import styled from 'styled-components';
import { Container } from 'styled-bootstrap-grid';
import breakpoint from 'styled-components-breakpoint';

const StyledContainer = styled(Container)`
  padding-top: 60px;
  padding-bottom: 60px;
  ${breakpoint('md', 'lg')`
    padding-left: 50px;
    padding-right: 50px;
  `}
  ${breakpoint('lg')`
    padding-top: 100px;
    padding-bottom: 100px;
  `}
  ${({ xsFluid }) => xsFluid && breakpoint('xs', 'sm')`
    width: 100%;
    max-width: unset;
  `}
  ${({ smFluid }) => smFluid && breakpoint('sm', 'md')`
    width: 100%;
    max-width: unset;
  `}
  ${({ mdFluid }) => mdFluid && breakpoint('md', 'lg')`
    width: 100%;
    max-width: unset;
  `}
  ${({ lgFluid }) => lgFluid && breakpoint('lg', 'xl')`
    width: 100%;
    max-width: unset;
  `}
  ${({ xlFluid }) => xlFluid && breakpoint('xl')`
    width: 100%;
    max-width: unset;
  `}
`;

export default StyledContainer;
