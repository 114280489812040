import React from 'react';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import { withTranslation } from "react-i18next";
import Banner from '../../chapter/Banner';
import LeasingList from './Sections/Leasing';

const StyledLeasing = styled.div``;

const Leasing = ({ t, i18n, ...props }) => {
  return (
    <StyledLeasing>
      <LeasingList {...props}/>
    </StyledLeasing>
  );
};

Leasing.propTypes = {};

Leasing.defaultProps = {};

export default withTranslation('global')(Leasing);
