import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import breakpoint from 'styled-components-breakpoint';
import { greys, reds, rgba } from '../../core/colors';

const StyledButton = styled.button`
  font-size: 1rem;
  line-height: 22px;
  outline: none;
  padding: 10px 24px;
  border: none;
  border-radius: 21px;
  font-weight: 600;
  cursor: pointer;
  position: relative;
  transition: all 0.2s ease-in;
  color: ${({ textColor }) => (textColor)};
  background-color: ${({ mainColor }) => (mainColor)};
  box-shadow: ${({ borderColor }) => (`inset 0 0 0 2px ${borderColor}`)};
  ${({ as }) => (as === 'a' ? `
    text-align: center;
  ` : '')}
  ${({ icon }) => (icon ? `
    padding-left: 55px;
    &:before {
      content: '';
      left: 24px;
      top: 50%;
      position: absolute;
      transform: translateY(-50%);
      background-image: url(${icon});
      height: 25px;
      width: 25px;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-position: center center;
    }
  ` : '')}
  &:hover, &:active {
    color: ${({ textHoverColor }) => (textHoverColor)};
    background-color: ${({ hoverColor }) => (hoverColor)};
    ${({ iconHover }) => (iconHover ? `
      &:before {
        background-image: url(${iconHover});
      }
    ` : '')}
    box-shadow: ${({ borderHoverColor }) => (`inset 0 0 0 2px ${borderHoverColor}`)};
  }
  ${breakpoint('xs', 'md')`
    width: 100%;
    ${({ as }) => (as === 'a' ? 'display: block;' : '')}
  `}
  ${breakpoint('md')`
    ${({ icon }) => (icon ? `
      padding-left: 50px;
        &:before {
          left: 20px;
        }
    ` : '')}
    ${({ as }) => (as === 'a' ? 'display: inline-block;' : '')}
  `}
`;

const Button = ({ children, component, link, linkSelf, onClickFn, mainColor, hoverColor, textColor, textHoverColor, borderColor, borderHoverColor, icon, iconHover, ...props }) => {
  return (
    <StyledButton
      as={component? component : undefined}
      mainColor={mainColor}
      hoverColor={hoverColor}
      textColor={textColor}
      textHoverColor={textHoverColor}
      href={component === 'a' ? link : undefined}
      target={linkSelf ? '_self' : '_blank'}
      onClick={component !== 'a' ? onClickFn : null}
      icon={icon ? icon : undefined}
      iconHover={iconHover ? iconHover : undefined}
      borderColor={borderColor}
      borderHoverColor={borderHoverColor}
      {...props}
    >
      {children}
    </StyledButton>
  )
};

Button.propTypes = {
  children: PropTypes.node,
  component: PropTypes.string,
  link: PropTypes.string,
  linkSelf: PropTypes.bool,
  onClickFn: PropTypes.func,
  mainColor: PropTypes.string,
  hoverColor: PropTypes.string,
  textColor: PropTypes.string,
  textHoverColor: PropTypes.string,
  borderColor: PropTypes.string,
  borderHoverColor: PropTypes.string,
  icon: PropTypes.string,
  iconHover: PropTypes.string,
};

Button.defaultProps = {
  children: undefined,
  component: undefined,
  link: undefined,
  linkSelf: false,
  onClickFn: () => {},
  mainColor: reds[2].main,
  hoverColor: reds[1].main,
  textColor: greys[1].main,
  textHoverColor: greys[1].main,
  borderColor: 'transparent',
  borderHoverColor: 'transparent',
  icon: undefined,
  iconHover: undefined,
};

export default Button;
