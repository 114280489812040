import React from 'react';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import { withTranslation } from "react-i18next";
import axios from 'axios';
import { reds, greys, rgba } from '../../../../core/colors';
import { Container, Row, Col } from '../../../../core/Grid';
import TitleTag from '../../../../component/TitleTag';
import PropertyCard from '../../../../card/Property';
import ZeroResult from '../../../../card/ZeroResult';
import Background from '../../../../chapter/Background';
import ArrowImage from './images/arrow.svg';
import GridImage from './images/grid.svg';
import GridWhiteImage from './images/grid_white.svg';
import ListImage from './images/list.svg';
import ListWhiteImage from './images/list_white.svg';

const PagingItem = styled.div`
  background-color: ${({ active }) => (active ? reds[2].main : rgba(greys[10].main, 0.15))};
  color: ${({ active }) => (active ? greys[1].main : greys[3].main)};
  font-size: 22px;
  line-height: 30px;
  font-weight: 800;
  height: 42px;
  width: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
`;

const Arrow = styled.div`
  background-image: url(${ArrowImage});
  background-size: 42px 42px;
  background-position: center center;
  background-repeat: no-repeat;
  height: 42px;
  width: 42px;
  cursor: pointer;
  ${({ isNext }) => (isNext ? 'transform: rotate(180deg);' : '')};
  ${({ isHidden }) => (isHidden ? 'visibility: hidden;' : '')};
`;

const PagingContainer = styled.div`
  margin-top: 60px;
  display: flex;
  justify-content: center;
  div {
    margin-right: 10px;
  }
  ${breakpoint('lg')`
    margin-top: 100px;
  `}
`;

const TypeButton = styled.div`
  padding: 10px 24px;
  background-color: ${({ active }) => (active ? reds[2].main : rgba(greys[10].main, 0.15))};
  color: ${({ active }) => (active ? greys[1].main : greys[3].main)};
  font-weight: 600;
  font-size: 1rem;
  line-height: 22px;
  border-radius: 21px;
  text-align: center;
  cursor: pointer;
  &:nth-child(n+2) {
    margin-top: 30px;
  }
  ${breakpoint('md')`
    &:nth-child(n+2) {
      margin-top: 0;
      margin-left: 15px;
    }
  `}
`;

const DisplayButton = styled.div`
  height: 50px;
  width: 50px;
  display: flex;
  border-radius: 50%;
  justify-content: center;
  cursor: pointer;
  background-color: ${({ active }) => (active ? reds[2].main : rgba(greys[10].main, 0.15))};
  background-image: url(${({ type, active }) => (type === 'grid' ? (active ? GridWhiteImage : GridImage) : (active ? ListWhiteImage : ListImage))});
  background-size: 50px 50px;
  background-position: center center;
  background-repeat: no-repeat;
  &:nth-child(n+2) {
    margin-left: 15px;
  }
`;

const SwitchDisplayContainer = styled.div`
  display: none;
  ${breakpoint('lg')`
    display: flex;
  `}
`;

const SwitchTypeContainer = styled.div`
  ${breakpoint('md')`
    display: flex;
  `}
`;

const SwitchContainer = styled.div`
  margin-top: 30px;
  ${breakpoint('md')`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  `}
  ${breakpoint('lg')`
    margin-top: 60px;
  `}
`;

const StyledCol = styled(Col)``

const ZeroResultContainer = styled.div`
  padding-top: 30px;
`;

const PropertyCardContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

const StyledLeasing = styled(Container)``;

const Leasing = ({ t, i18n, location, ...props }) => {
  const [lettablePremisesData, setLettablePremisesData] = React.useState();
  const [lettableCarParkingSpaceData, setLettableCarParkingSpaceData] = React.useState();
  const [isLoading, setIsLoading] = React.useState(true);
  const [displayData, setDisplayData] = React.useState();
  const [currentType, setCurrentType] = React.useState('lettablePremises');
  const [displayType, setDisplayType] = React.useState('list');
  const [pageCount, setPageCount] = React.useState(1);
  const getCurrentPage = (location?.search) ? Number(location?.search.split('?page=')[1]) : 1;
  const [currentPage, setCurrentPage] = React.useState(getCurrentPage);

  const onePageLimit = 6;

  React.useEffect(() => {
    axios.get(`../../data/lettablepremises.json?timestamp=${new Date().getTime()}`)
    .then(res => {
      const premisesData = res.data;
      setLettablePremisesData(premisesData);
    })

    axios.get(`../../data/lettablecarparkingspace.json?timestamp=${new Date().getTime()}`)
    .then(res => {
      const parkingData = res.data;
      setLettableCarParkingSpaceData(parkingData);
    })

    if(location?.pathname.match(/lettablePremises/g)) {
      setCurrentType('lettablePremises');
    }
    if(location?.pathname.match(/lettableCarParkingSpace/g)) {
      setCurrentType('lettableCarParkingSpace');
    }

  }, [location]);

  React.useEffect(() => {
    if(currentType === 'lettablePremises')setDisplayData(lettablePremisesData);
    else setDisplayData(lettableCarParkingSpaceData);
  }, [currentType, lettablePremisesData, lettableCarParkingSpaceData]);


  React.useEffect(() => {
    if(displayData && displayData.data ) {
      setIsLoading(false);
      const pageCount = displayData.data.length / onePageLimit;
      setPageCount(pageCount >= 1 ? Number(pageCount.toFixed(0)) : 1);
    }
  }, [displayData]);

  const changeTypeHandle = (type) => {
    const link = `/${i18n.language}/leasing/${type}`;
    window.location = link;
    // setCurrentType(type);
    // setCurrentPage(1);
  };

  const changePageHandle = (isNext) => {
    const nextPage = (isNext) ? currentPage + 1 : currentPage - 1;
    const link = `/${i18n.language}/leasing/${currentType}?page=${nextPage}`;
    window.location = link;
    // if(isNext) setCurrentPage(currentPage + 1);
    // else setCurrentPage(currentPage - 1);
  };

  const changePageClickHandle = (page) => {
    const link = `/${i18n.language}/leasing/${currentType}?page=${page}`;
    window.location = link;
  };

  const changeDisplayHandle = (type) => {
    setDisplayType(type);
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 0);
  }

  return (
    <Background>
      <StyledLeasing>
        <Row>
          <StyledCol col={12}>
            <TitleTag borderPosition="bottom" borderAlign="center" textAlign={"center"}>
              {currentType === 'lettablePremises' ? (
                t('leasing:lettablePremises')
              ) : (
                t('leasing:lettableCarParkingSpace')
              )}
            </TitleTag>
          </StyledCol>
          <StyledCol col={12}>
            <SwitchContainer>
              <SwitchTypeContainer>
                <TypeButton active={currentType === 'lettablePremises'} onClick={() => changeTypeHandle('lettablePremises')}>{t('leasing:lettablePremises')}</TypeButton>
                <TypeButton active={currentType === 'lettableCarParkingSpace'} onClick={() => changeTypeHandle('lettableCarParkingSpace')}>{t('leasing:lettableCarParkingSpace')}</TypeButton>
              </SwitchTypeContainer>
              {currentType === 'lettablePremises' && displayData && displayData?.data && displayData?.data.length > 0 && (
                <SwitchDisplayContainer>
                  <DisplayButton active={displayType === 'list'} type={"list"} onClick={() => changeDisplayHandle('list')} />
                  <DisplayButton active={displayType === 'grid'} type="grid" onClick={() => changeDisplayHandle('grid')} />
                </SwitchDisplayContainer>
              )}
            </SwitchContainer>
          </StyledCol>
          <PropertyCardContainer>
            {!isLoading && (
              currentType === 'lettablePremises' && (displayData && displayData?.data && displayData?.data.length > (onePageLimit * (currentPage-1))) ? (
                displayData.data.slice((onePageLimit * currentPage) - onePageLimit, onePageLimit * currentPage).map((v, i) => (
                  <StyledCol col={12} lg={displayType === 'grid' ? 4 : 12} key={`image_${i}`}>
                    <PropertyCard data={v} type={displayData?.type} isGrid={displayType === 'grid'} pageNumber={currentPage}/>
                  </StyledCol>
                ))
              ) : (
                <StyledCol col={12}>
                  <ZeroResultContainer>
                    <ZeroResult isParking={currentType === 'lettableCarParkingSpace'} isAvailable={displayData?.available} />
                  </ZeroResultContainer>
                </StyledCol>
              )
            )}
          </PropertyCardContainer>
          {pageCount > 1 && (
            <StyledCol col={12}>
              <PagingContainer>
                <Arrow isHidden={currentPage === 1} onClick={() => changePageHandle()} />
                {pageCount && [...Array(pageCount)].map((v, i) => (
                  <PagingItem active={currentPage === (i + 1)} onClick={() => changePageClickHandle(i+1)} key={`page_${i}`}>{i+1}</PagingItem>
                ))}
                <Arrow isHidden={currentPage >= pageCount} isNext onClick={() => changePageHandle(true)} />
              </PagingContainer>
            </StyledCol>
          )}
        </Row>
      </StyledLeasing>
    </Background>
  )
};

Leasing.propTypes = {};

Leasing.defaultProps = {};

export default withTranslation(['home', 'leasing'])(Leasing);
