import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { greys, rgba } from '../../../../core/colors';

const SwiperImg = styled.img`
  width: inherit;
  height: inherit;
  &.swiper-lazy-loaded + div {
    display: none;
  }
`;

const SwiperSlide = styled.div`
  height: inherit;
  width: inherit;
  position: relative;
  display: inline-block;
`;

const PlaceHolderLoad = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: ${greys[2].main};
  background-size: 50% 50%;
  background-repeat: no-repeat;
  background-position: center;  
`;

const StyledSwipeSlide = styled.div`
  height: 100%;
  position: relative;
  &:before {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 2;
    pointer-events: none;
    background: linear-gradient(to bottom, ${rgba(greys[1].main, 0)} 0%, ${rgba(greys[10].main, 0.95)} 100%);
  }
`;

const SwipeSlide = ({
  imageUrl, link, alt, interval, openInNewWindow, dataGtmAction, onSlideClick, ...props
}) => (
  imageUrl ? (
    <StyledSwipeSlide {...props} data-swiper-autoplay={interval}>
      <SwiperSlide
        title={alt || ''}
      >
        <SwiperImg data-src={imageUrl} alt={alt} className="swiper-lazy" />
        <PlaceHolderLoad className="swiper-lazy-load" />
      </SwiperSlide>
    </StyledSwipeSlide>
  ) : null
);

SwipeSlide.propTypes = {
  imageUrl: PropTypes.string,
  alt: PropTypes.string,
};

SwipeSlide.defaultProps = {
  imageUrl: undefined,
  alt: undefined,
};

export default SwipeSlide;
