import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import langEn from "./lang/en/global.json";
import langTc from "./lang/tc/global.json";
import langHeaderEn from "./lang/en/header.json";
import langHeaderTc from "./lang/tc/header.json";
import langHomeEn from "./lang/en/home.json";
import langHomeTc from "./lang/tc/home.json";
import langLeasingEn from './lang/en/leasing.json';
import langLeasingTc from './lang/tc/leasing.json';
import langPropertyEn from './lang/en/property.json';
import langPropertyTc from './lang/tc/property.json';
import langPrivacyEn from './lang/en/privacy.json';
import langPrivacyTc from './lang/tc/privacy.json';
import langDisclaimerEn from './lang/en/disclaimer.json';
import langDisclaimerTc from './lang/tc/disclaimer.json';
import langContactEn from './lang/en/contact.json';
import langContactTc from './lang/tc/contact.json';


const Option = {
  // order and from where user language should be detected
  order: ['querystring', 'path'],

  // keys or params to lookup language from
  lookupQuerystring: 'lang',
  lookupFromPathIndex: 0,
}

i18n.use(LanguageDetector).init({
  // we init with resources
  resources: {
    en: {
      global: langEn,
      header: langHeaderEn,
      home: langHomeEn,
      leasing: langLeasingEn,
      property: langPropertyEn,
      privacy: langPrivacyEn,
      disclaimer: langDisclaimerEn,
      contact: langContactEn
    },
    tc: {
      global: langTc,
      header: langHeaderTc,
      home: langHomeTc,
      leasing: langLeasingTc,
      property: langPropertyTc,
      privacy: langPrivacyTc,
      disclaimer: langDisclaimerTc,
      contact: langContactTc
    }
  },
  detection: Option,
  fallbackLng: "en",
  // lng: "en",
  debug: false,
  // have a common namespace used around the full app
  keySeparator: false, // we use content as keys

  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ","
  },

  react: {
    wait: true
  }
});

export default i18n;
