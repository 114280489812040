import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import breakpoint from 'styled-components-breakpoint';
import { greys } from '../../core/colors';

const StyledParagraph = styled.p`
  font-size: 1.125rem;
  line-height: 35px;
  margin-top: 30px;
  ${({ textAlign }) => (textAlign ? `text-align: ${textAlign};` : '')};
  color: ${({ textColor }) => (textColor)};
`;

const Paragraph = ({ children, textAlign, textColor }) => (
  <StyledParagraph textAlign={textAlign} textColor={textColor}>{children}</StyledParagraph>
);

Paragraph.propTypes = {
  children: PropTypes.node,
  textAlign: PropTypes.string,
  textColor: PropTypes.string,
};

Paragraph.defaultProps = {
  title: undefined,
  textAlign: undefined,
  textColor: greys[4].main,
};

export default Paragraph;
