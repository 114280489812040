import React from 'react';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import { withTranslation } from "react-i18next";
import Banner from '../../chapter/Banner';
import About from './Sections/About';
import Contact from './Sections/Contact';
import IframeMap from '../../component/IframeMap';
import Leasing from './Sections/Leasing';


const StyledHome = styled.div``;

const Home = ({ t, i18n, ...props }) => {

  return (
    <StyledHome>
      <Banner title={t('title')} subTitle={t('subTitle')} />
      <About />
      <Contact />
      <Leasing />
      <IframeMap />
    </StyledHome>
  );
};

Home.propTypes = {};

Home.defaultProps = {};

export default withTranslation('global')(Home);
