import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import breakpoint from 'styled-components-breakpoint';
import { NavLink } from 'react-router-dom';
import { greys, blues, rgba } from '../../core/colors';
import { withTranslation } from "react-i18next";
import { Container, Row, Col } from '../../core/Grid';
import FooterLogoImage from './images/footer-logo.svg';
import TopIcon from './images/arrow.svg';

const BackToTop = styled.div`
  position: absolute;
  right: 20px;
  top: -36px;
  width: 74px;
  height: 74px;
  border-radius: 50%;
  cursor: pointer;
  background-color: ${greys[2].main};
  box-shadow: 0px 1px 4px 0px ${rgba(greys[10].main, 0.15)};
  &:before {
    content: '';
    position: absolute;
    height: 51px;
    width: 51px;
    background-image: url(${TopIcon});
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    transform: translateX(-50%) translateY(-50%) rotate(90deg);
    left: 50%;
    top: 50%;
  }
`;

const StyledCol = styled(Col)`
  line-height: normal;
`;

const OrderCol = styled(StyledCol)`
  ${breakpoint('lg')`
    &:first-child {
      order: 2;
    }
    &:last-child {
      order: 1;
    }
  `}

`;

const ALinkCSS = css`
  color: ${greys[1].main};
  font-size: 1.0625rem;
  line-height: 23px;
  font-weight: 600;
  &:hover {
    text-decoration: underline;
  }
`;

const Copyright = styled.div`
  font-size: 14px;
  line-height: 19px;
  font-weight: 600;
  margin-top: 30px;
  ${breakpoint('md', 'lg')`
    text-align: center;
  `}
  ${breakpoint('lg')`
    margin-top: 0;
  `}
`;

const LangSwitcher = styled.div`
  text-align: center;
  a {
    ${ALinkCSS};
    &:last-child {
      margin-left: 34px;
    }
  }
  ${breakpoint('lg')`
    text-align: right;
  `}
`;

const UnderLine = styled.div`
  height: 1px;
  background-color: ${rgba(greys[1].main, .35)};
  width: 100%;
  margin-top: 60px;
  margin-bottom: 30px;
  ${breakpoint('lg')`
    margin-top: 50px;
  `}
`;

const StyledLi = styled.li`
  max-width: 50%;
  flex: 0 0 50%;
  margin-bottom: 10px;
  line-height: normal;
  a {
    ${ALinkCSS};
    display: block;
  }
`;

const StyledUl = styled.ul`
  display: flex;
  list-style: none;
  padding: 0px;
  margin: 0px;
  height: inherit;
  position: relative;
  flex-wrap: wrap;
`;

const MenuNav = styled.div`
  margin-top: 30px;
  line-height: normal;
`;

const Address = styled.div`
  ${ALinkCSS};
  &:hover {
    text-decoration: none;
  }
`;

const TelFax = styled.div`
  margin-bottom: 10px;
  span {
    &:first-child {
      font-size: 1.0625rem;
      line-height: 23px;
      font-weight: 800;
    }
    &:last-child {
      margin-left: 5px;
      font-size: 0.875rem;
      line-height: 19px;
      font-weight: normal;
    }
  }
`;

const ContactInfo = styled.div`
  margin-top: 30px;
  line-height: normal;
`;

const Tag = styled.div`
  font-size: 1.375rem;
  line-height: 30px;
  font-weight: 600;
  text-align: center;
  margin-top: 30px;
  ${breakpoint('md')`
    margin-top: 54px;
  `}
  ${breakpoint('lg')`
    margin-top: 0;
    text-align: left;
  `}
`;

const PingPingInfo = styled.div`
  font-size: 1.0625rem;
  line-height: 23px;
  font-weight: 600;
  margin-top: 30px;
`;

const FooterLogo = styled.div`
  width: 263px;
  height: 58px;
  z-index: 3;
  position: relative;
  vertical-align: middle;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-image: url(${FooterLogoImage});
  ${breakpoint('md', 'lg')`
    margin: 0 auto;
  `}
`;

const StyledFooter = styled.div`
  color: ${greys[1].main};
  background-color: ${blues[1].main};
  position: relative;
`;

const Footer = ({ t, i18n, match, location }) => {
  const matchLink = "/" + match.url.split('/')[1];
  let timeOut;
  const backTopFn = () => {
    if(document.body.scrollTop !== 0 || document.documentElement.scrollTop !== 0) {
      window.scrollBy(0, -50);
      timeOut = setTimeout(() => {
        backTopFn();
      }, 10);
    } else {
      clearTimeout(timeOut);
    }
  }
  return (
    <StyledFooter>
      <BackToTop onClick={() => backTopFn()}/>
      <Container>
        <Row>
          <StyledCol col={12} lg={4}>
            <FooterLogo />
            <PingPingInfo>
              {t('home:about', { returnObjects: true })['description']}
            </PingPingInfo>
          </StyledCol>
          <StyledCol col={12} md={6} lg={4}>
            <Tag>{t('global:contactUs')}</Tag>
            <ContactInfo>
              <TelFax>
                <span>T</span>
                <span>{t('global:tel')}</span>
              </TelFax>
              <TelFax>
                <span>F</span>
                <span>{t('global:fax')}</span>
              </TelFax>
              <TelFax>
                <span>E</span>
                <span>{t('global:email')}</span>
              </TelFax>
              <Address>
                {t('global:address')}
              </Address>
            </ContactInfo>
          </StyledCol>
          <StyledCol col={12} md={6} lg={4}>
            <Tag>{t('global:page')}</Tag>
            <MenuNav>
              <StyledUl>
                {t('header:menu', { returnObjects: true }).map((value, index) => (
                  <StyledLi key={index}>
                    <NavLink exact to={`${matchLink}/${value.key}`}>{value.name}</NavLink>
                  </StyledLi>
                ))}
                <StyledLi>
                  <NavLink exact to={`${matchLink}/privacy`}>{t('header:privacy')}</NavLink>
                </StyledLi>
                <StyledLi>
                  <NavLink exact to={`${matchLink}/disclaimer`}>{t('header:disclaimer')}</NavLink>
                </StyledLi>
              </StyledUl>
            </MenuNav>
          </StyledCol>
        </Row>
        <Row>
          <StyledCol col={12}>
            <UnderLine></UnderLine>
          </StyledCol>
        </Row>
        <Row>
          <OrderCol col={12} lg={6}>
            <LangSwitcher>
              <a href={`${location.pathname.replace(match.url, "/tc")}`}>{t('header:tc')}</a>
              <a href={`${location.pathname.replace(match.url, "/en")}`}>{t('header:en')}</a>
            </LangSwitcher>
          </OrderCol>
          <OrderCol col={12} lg={6}>
            <Copyright>
              {t('global:copyright')}
            </Copyright>
          </OrderCol>
        </Row>
      </Container>
    </StyledFooter>
  );
};

Footer.propTypes = {
  match: PropTypes.object,
  location: PropTypes.object,
};

Footer.defaultProps = {
  match: {},
  location: {},
};

export default withTranslation(["global", "header", "home"])(Footer);
