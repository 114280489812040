import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch, Redirect} from 'react-router-dom'
import PingPingThemeProvider from './core/PingPingThemeProvider';
import TranslationProvider from './core/TranslationProvider';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <React.StrictMode>
    <TranslationProvider>
      <PingPingThemeProvider>
        <Router>
          <Switch>
            <Route path="/:lang" component={App} />
            <Redirect to="/en" />
          </Switch>
        </Router>
        </PingPingThemeProvider>
      </TranslationProvider>
  </React.StrictMode>,
  document.getElementById('root')
);



// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
