import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import LazyLoad from 'react-lazyload';
import breakpoint from 'styled-components-breakpoint';
import { withTranslation } from "react-i18next";
import { rgba, greys, blues } from '../../../../core/colors';
import { Container, Row, Col } from '../../../../core/Grid';
import TitleTag from '../../../../component/TitleTag';
import Paragraph from '../../../../component/Paragraph';
import Button from '../../../../component/Button';
import AboutImg from './images/about.jpg';
import QuotationMarkBlue from './images/quotation-mark.svg';
import QuotationMarkWhite from './images/quotation-mark-white.svg';

const MessageDisplay = styled.div`
  color: ${greys[1].main};
  padding: 30px 35px 30px 30px;
  background-color: ${blues[1].main};
  border-radius: 15px;
  max-width: 224px;
  font-size: 1.5625rem;
  font-weight: 800;
  position: relative;
  margin-left: auto;
  margin-top: -58px;
  line-height: normal;
  &:before, &:after {
    content: '';
    background-size: 41px 42px;
    background-position: center center;
    background-repeat: no-repeat;
    height: 41px;
    width: 42px;
    position: absolute;
    ${breakpoint('lg')`
      height: 92px;
      width: 92px;
      background-size: 92px 92px;
    `};
  }
  &:before {
    background-image: url(${QuotationMarkBlue});
    left: 0;
    transform: translateX(calc(-100% - 1px)) translateY(30px);
    ${breakpoint('md')`
      transform: translateX(calc(-100% - 1px)) translateY(50px);
    `};
    ${breakpoint('lg')`
      top: 0;
      transform: translateX(calc(-100% - 1px));
    `};
  }
  &:after {
    background-image: url(${QuotationMarkWhite});
    right: 10px;
    bottom: 0;
    ${breakpoint('lg')`
      right: 0;
      transform: translateY(50%);
    `};
  }
  ${breakpoint('md')`
    margin-top: -80px;
    margin-right: 30px;
  `}
  ${breakpoint('lg')`
    max-width: 409px;
    margin: 0;
    padding: 30px;
    font-size: 2.1875rem;
    position absolute;
    bottom: 130px;
    left: -100px;
  `}
`;

const Photo = styled.div`
  background-image: url(${AboutImg});
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  height: inherit;
  width: inherit;
`;
const PhotoDisplay = styled.div`
  width: 100%;
  height: 465px;
  border-radius: 15px;
  overflow: hidden;
  background-color: ${greys[3].main};
  box-shadow: 0 1px 4px 0 ${rgba(greys[10].main, 0.15)};
  ${breakpoint('xs', 'md')`
    margin-top: 30px;
  `}
  ${breakpoint('lg')`
    height: 660px;
  `}
`;

const PhotoMessageContainer = styled.div`
  position: relative;
  ${breakpoint('md')`
    margin-top: -100px;
  `}
  ${breakpoint('lg')`
    margin-top: -130px;
    margin-bottom: -130px;
  `}
`;

const ButtonContainer = styled.div`
  margin-top: 30px;
`;

const StyledCol = styled(Col)`
  ${breakpoint('md')`
    padding-left: 50px;
    padding-right: 50px;
    &:first-child { padding-right: 0; }
    &:last-child { padding-left: 30px; }
  `}
  ${breakpoint('lg')`
    padding-left: 20px;
    padding-right: 20px;
    &:last-child { padding-left: 80px; }
  `}
`;

const StyledAbout = styled(Container)`
  position: relative;
  z-index: 1;
`;

const About = ({ t, i18n }) => {
  return (
    <StyledAbout>
      <Row>
        <StyledCol col={12} md={5.72} lg={6.15} mdNoGutter lgNoGutter>
          <TitleTag borderPosition="bottom" borderAlign="left">{t('home:about', { returnObjects: true })['title']}</TitleTag>
          <Paragraph>{t('home:about', { returnObjects: true })['description']}</Paragraph>
          <ButtonContainer>
            <Button component="a" link={`/${i18n.language}/about`} linkSelf>{t('global:learnMore')}</Button>
          </ButtonContainer>
        </StyledCol>
        <StyledCol col={12} md={6.28} lg={5.33} mdNoGutter lgNoGutter>
          <PhotoMessageContainer>
            <PhotoDisplay>
              <LazyLoad once offset={200}>
                <Photo />
              </LazyLoad>
            </PhotoDisplay>
            <MessageDisplay>
              {t('home:about', { returnObjects: true })['headline']}
            </MessageDisplay>
          </PhotoMessageContainer>
        </StyledCol>
      </Row>
    </StyledAbout>
  )
};

About.propTypes = {};

About.defaultProps = {};

export default withTranslation(['global', 'home'])(About);
