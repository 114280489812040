import React, { useEffect } from 'react';
import { ThemeProvider, createGlobalStyle } from 'styled-components';
import { GridThemeProvider, BaseCSS } from 'styled-bootstrap-grid';
import { Normalize } from 'styled-normalize';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';
import { withTranslation } from "react-i18next";
import breakpoint from 'styled-components-breakpoint';
import defaultTheme from '../defaultTheme';
// import { TranslationContextProvider } from '../TranslationProvider';
// import translation from '../assets/locales';

// styled-bootstrap-grid
const getGridTheme = ({ breakpoints, container, row, col }) => ({
  breakpoints,
  container,
  row,
  col,
});

/* TODO: remove me if really no component using me (2020/03/06) */
/* @import url('https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.7.2/animate.min.css'); */
const GlobalStyle = createGlobalStyle`
  
  * {
    box-sizing: border-box;
    font-weight: normal;
    font-family: 'Open Sans', sans-serif;
  }
  html {
    height: 100%;
    font-size: ${({ theme }) => theme.typography.size};  
    line-height: ${({ theme }) => theme.typography.lineHeight};
    /* scroll-behavior: smooth; */
  }

  body {
    font-size: ${({ theme }) => theme.typography.size};  
    line-height: ${({ theme }) => theme.typography.lineHeight};
    width: 100%;
    /* scroll-behavior: smooth; */
    &.bodyLock {
      position: fixed;
      overflow: hidden;
      right: 0;
      bottom: 0;
      left: 0;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin: 0;
      font-weight: bold;
    }

    h1 {
      font-size: 2.1875rem;
      line-height: 47px;
      ${breakpoint('lg')`
        font-size: 2.8125rem;
        line-height: 61px;
      `}
    }

    h2 {
      font-size: 1.5625rem;
      line-height: 34px;
      ${breakpoint('lg')`
        font-size: 2.1875rem;
        line-height: 47px;
      `}
      &.page-title {
        font-size: 2.1875rem;
        line-height: 47px;
        ${breakpoint('lg')`
          font-size: 2.8125rem;
          line-height: 61px;
        `}
      }
    }

    h3 {
      font-size: 1.75rem;
      line-height: 38px;
    }

    h4 {
      font-size: 1.375rem;
      line-height: 30px;
    }

    h5 {
      font-size: 1.25rem;
      line-height: 35px;
    }

    a, a:hover, a:active, a:link, a:focus, a:visited {
      text-decoration: none;
    }
    p {
      padding: 0;
      margin: 0;
      font-size: 1.125rem;
      word-break: break-word;
      ${breakpoint('lg')`
        font-size: 1.125rem;
      `}
    }
    .grecaptcha-badge {
      z-index: 9999;
    }
  } 
`;

const PingPingThemeProvider = ({ theme: propsTheme, i18n, children }) => {
  // useEffect(() => {
  //   objectFitImages();
  // }, []);

  const theme = {
    ...defaultTheme,
    ...propsTheme,
    langCode: i18n?.language || 'en',
    offsetHeight: "60px",
    isXsUp: useMediaQuery({ query: `(min-width: ${defaultTheme.breakpoints.xs}px)` }),
    isSmUp: useMediaQuery({ query: `(min-width: ${defaultTheme.breakpoints.sm}px)` }),
    isMdUp: useMediaQuery({ query: `(min-width: ${defaultTheme.breakpoints.md}px)` }),
    isLgUp: useMediaQuery({ query: `(min-width: ${defaultTheme.breakpoints.lg}px)` }),
    isXlUp: useMediaQuery({ query: `(min-width: ${defaultTheme.breakpoints.xl}px)` }),
  };

  return (
    <ThemeProvider theme={theme}>
      <GridThemeProvider gridTheme={getGridTheme(theme)}>
        {/* <TranslationContextProvider translation={translation[langCode]}> */}
          <BaseCSS />
          <Normalize />
          {/* <GoogleFontLoader
            fonts={[
              {
                font: 'Nunito Sans',
                weights: [300, 400, 600, 700, 800],
              },
            ]}
          /> */}
          <GlobalStyle theme={theme} langCode={i18n?.language || 'en'} />
          {children}
        {/* </TranslationContextProvider> */}
      </GridThemeProvider>
    </ThemeProvider>
  );
};

PingPingThemeProvider.propTypes = {
  theme: PropTypes.object,
  children: PropTypes.node.isRequired,
  t: PropTypes.func,
  i18n: PropTypes.object,
};

PingPingThemeProvider.defaultProps = {
  theme: {},
  t: () => {},
  i18n: {},
};

export default withTranslation()(PingPingThemeProvider);
