import React, { useContext } from 'react';
import styled, { ThemeContext, css } from 'styled-components';
import PropTypes from 'prop-types';
import breakpoint from 'styled-components-breakpoint';
import { withTranslation } from "react-i18next";
import { greys, reds, blues } from '../../core/colors';
import Slider from '../../component/Slider';
import Button from '../../component/Button';
import PDFImage from './images/pdf.svg';
import ThumbnailContainer from '../../component/ThumbnailContainer';

const Type = styled.div`
  position: absolute;
  top: 30px;
  left: 50%;
  white-space: nowrap;
  color: ${greys[1].main};
  z-index: 2;
  border-radius: 21px;
  transform: translateX(-50%);
  background-color: ${blues[1].main};
  padding: 10px 22px;
  font-size: 1rem;
  line-height: normal;
  font-weight: bold;
  padding: 10px 22px;
  ${breakpoint('md', 'lg')`
    padding: 10px 28px;
  `}
`;

const Inner = styled.div`
  width: inherit;
  height: inherit;
  position: relative;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  padding: 20px 10px;
  z-index: 2;
  pointer-events: none;
  ${breakpoint('md')`
    padding: 30px;
    position: absolute;
  `}
  ${breakpoint('lg')`
    padding: 20px 15px;
    transform: translateZ(60px) scale(.94);
  `}
`;

const InnerClone = styled(Inner)`
  &&& {
    position: absolute;
    padding: 0;
  }
`;

const ButtonContainer = styled.div`
  text-align: center;
  pointer-events: auto;
  a {
    width: auto;
    display: inline-block;
    ${breakpoint('md', 'lg')`
      position: absolute;
      right: 15px;
      bottom: 15px;
      display: block;
      text-align: left;
    `}
  }
  ${breakpoint('lg')`
    padding-top: 15px;
  `}
`;

const Item = styled.div`
  font-size: 1.25rem;
  line-height: 35px;
  text-align: center;
  color: ${greys[1].main};
  ${breakpoint('md', 'lg')`
    text-align: left;
  `}
`;

const Tag = styled.div`
  font-size: 1.75rem;
  line-height: 38px;
  text-align: center;
  color: ${greys[1].main};
  font-weight: bold;
  ${breakpoint('md', 'lg')`
    text-align: left;
  `}
`;

const InfoItem = styled.div`
  margin-bottom: 10px;
  &:last-child {
    margin-bottom: 0;
  }
  ${breakpoint('lg')`
    margin-bottom: 15px;
  `}
`;

const PDFIcon = styled.div`
  background-image: url(${PDFImage});
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center center;
  height: 59px;
  width: 59px;
  margin: 0 auto;
  margin-bottom: 10px;
  ${breakpoint('md', 'lg')`
    position: absolute;
    top: 15px;
    right: 15px;
  `}
`;

const Address = styled.h3`
  z-index: 3;
  text-align: center;
  position: absolute;
  display: flex;
  height: 100%;
  width: 100%;
  padding: 42px;
  color: ${greys[1].main};
  justify-content: center;
  align-items: center;
  pointer-events: none;
  ${breakpoint('lg')`
    padding: 15px;
  `}
`;

const InfoContainer = styled.div`
  position: relative;
  border-radius: 0 0 15px 15px;
  background-color: ${reds[2].main};
  ${breakpoint('md')`
    width: 100%;
    border-radius: 0 15px 15px 0;
  `}
  ${breakpoint('lg')`
   border-radius: 15px;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    transition: transform 0.6s ease;
    transform: rotateY(180deg);
    transform-style: preserve-3d;
    backface-visibility: hidden;
  `}
`;

const SliderCss = css`
  overflow: hidden;
  border-radius: 15px 15px 0 0;
  ${breakpoint('md')`
    border-radius: 15px 0 0 15px;
  `}
  ${breakpoint('lg')`
    border-radius: 15px;
  `}
`;

const SliderContainer = styled.div`
  overflow: hidden;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  ${breakpoint('lg')`
    .swiper-button-prev,
    .swiper-button-next,
    .swiper-pagination {
      display: none;
    }
  `}
  ${SliderCss};
  .swiper-container {
    ${SliderCss};
    ${breakpoint('lg')`
      pointer-events: none;
    `}
  }
`;

const StyledLandingProperty = styled.div`
  margin-top: 30px;
  ${breakpoint('md')`
    display: flex;
  `}
  &:hover {
    .thumbnail-container  {
      ${breakpoint('lg')`
        transform: rotateY(-180deg);
      `}
    }
    ${InfoContainer} {
      ${breakpoint('lg')`
        transform: rotateY(0deg);
      `}
    }
  }
  ${breakpoint('lg')`
    margin-top: 0;
    height: 100%;
    transform-style: preserve-3d;
    perspective: 1000px;
  `}
`;

const LandingProperty = ({ t, i18n, data, type, typeName, ...props }) => {
  const { isLgUp } = useContext(ThemeContext);
  const { language } = i18n;

  return (
    <StyledLandingProperty>
      {data?.thumbnail && data?.thumbnail.length > 0 && (
        <>
          <ThumbnailContainer with3D>
            <InnerClone>
              {data?.[language]?.address && (<Address>{data[language].address}</Address>)}
              {type && (<Type>{typeName || t(`leasing:${type}`)}</Type>)}
            </InnerClone>
            <SliderContainer>
              <Slider imageList={data.thumbnail} limit={isLgUp ? 1 : 3} />
            </SliderContainer>
          </ThumbnailContainer>
          <InfoContainer>
            <Inner className="Inner">
              {data?.pdf && (<PDFIcon />)}
              {data?.area && (
                <InfoItem>
                  <Tag>{t('leasing:grossArea')}</Tag>
                  {data?.area && (<Item>{t('leasing:area', { area: Number(data.area).toLocaleString('zh-HK') })}</Item>)}
                </InfoItem>
              )}
              {data?.[language]?.date && (
                <InfoItem>
                  <Tag>{t('leasing:date')}</Tag>
                  <Item>{data[language].date}</Item>
                </InfoItem>
              )}
              <ButtonContainer>
                <Button
                  component="a"
                  link={`/${language}/detail/lettablePremises/${data?.id}`}
                  linkSelf
                  mainColor={reds[2].main}
                  hoverColor={reds[1].main}
                  textColor={greys[1].main}
                  borderColor={greys[1].main}
                  borderHoverColor={greys[1].main}
                >
                  {t('global:detail')}
                </Button>
              </ButtonContainer>
            </Inner>
          </InfoContainer>
        </>
      )}
    </StyledLandingProperty>
  );
};

LandingProperty.propTypes = {
  data: PropTypes.object,
  type: PropTypes.string,
  typeName: PropTypes.string,
};

LandingProperty.defaultProps = {
  data: {},
  type: undefined,
  typeName: undefined,
};

export default withTranslation(['global', 'leasing'])(LandingProperty);
