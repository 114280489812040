import React from 'react';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import { Container, Row, Col } from '../../core/Grid';
import { withTranslation } from "react-i18next";
import TitleTag from '../../component/TitleTag';
import Background from '../../chapter/Background';
import { rgba, greys, blues } from '../../core/colors';
import Slider from '../../component/Slider';
import Paragraph from '../../component/Paragraph';
import InfoMobileImg from './images/mobile_info.jpg';
import InfoDesktopImg from './images/desktop_info.jpg';
import Slide01Img from './images/slide_04.jpg';
import Slide02Img from './images/slide_05.jpg';
import Slide03Img from './images/slide_01.jpg';
import Slide04Img from './images/slide_06.jpg';
import Slide05Img from './images/slide_03.jpg';
import Slide06Img from './images/slide_07.jpg';

const SliderContainer = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  margin-left: -10px;
  z-index: 2; 
  ${breakpoint('lg')`
    margin-left: 0;
  `}
  .swiper-slide {
    &:before {
      background: none;
    }
  }
  .swiper-container {
    border-radius: 15px;
  }
  .swiper-button-prev,
  .swiper-button-next {
    height: 100%;
    top: 0;
    padding: 10px;
  }
  .swiper-button-prev {
    left: 0;
    background: linear-gradient(to left, ${rgba(greys[1].main, 0)} 0%, ${rgba(greys[10].main, 0.5)} 100%);
  }
  .swiper-button-next {
    right: 0;
    background: linear-gradient(to right, ${rgba(greys[1].main, 0)} 0%, ${rgba(greys[10].main, 0.5)} 100%);
  }
  .swiper-pagination {
    margin-top: 30px;
    bottom: -66px;
    position: absolute;
    .swiper-pagination-bullet {
      border: 1px solid ${blues[1].main};
      &.swiper-pagination-bullet-active {
        background-color: ${blues[1].main};
      }
    }
  }
`;

const DisplaySliderContainer = styled.div`
  margin-top: 30px;
  height: 238px;
  position: relative;
  margin-bottom: 66px;
  img {
    object-fit: cover;
  }
  ${breakpoint('md')`
    max-width: 335px;
    height: 238px;
    left: 50%;
    transform: translateX(-50%);
  `}
  ${breakpoint('lg')`
    margin-top: 60px;
    max-width: 464px;
    height: 330px;
  `}
`;

const AboutTitle = styled.h3`
  font-size: 2.1875rem;
  line-height: 47px;
  font-weight: bold;
  color: ${blues[1].main};
  margin-top: 30px;
  ${breakpoint('lg')`
    margin-top: 60px;
    font-size: 45px;
    line-height: 61px;
    &:first-of-type {
      margin-top: 90px;
    }
  `}
`;

const ObjectBack = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 20px;
  right: -10px;
  border-radius: 15px;
  overflow: hidden;
  background-color: ${blues[1].main};
  ${breakpoint('md')`
    right: 0;
    left: 20px;
    width: calc(100% - 20px);
  `}
`;

const ObjectBackClone = styled(ObjectBack)`
  ${breakpoint('lg')`
    width: 100%;
  `}
`;

const Photo = styled.div`
  background-image: url(${InfoMobileImg});
  background-position: center bottom;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 15px;
  overflow: hidden;
  margin-left: -10px;
  height: 100%;
  width: 100%;
  position: relative;
  z-index: 2;
  background-color: ${greys[3].main};
  box-shadow: 0 1px 4px 0 ${rgba(greys[10].main, 0.15)};
  ${breakpoint('md')`
    margin-left: 0;
    background-image: url(${InfoDesktopImg});
  `}
`;

const PhotoDisplay = styled.div`
  height: 465px;
  width: 100%;
  margin-top: 30px;
  position: relative;
  margin-bottom: 20px;
  ${breakpoint('md')`
    padding-right: 20px;
  `}
  ${breakpoint('lg')`
    margin-top: 60px;
    height: 666px;
  `}
`;

const StyledCol = styled(Col)``;

const StyledProperty = styled(Container)``;

const Property = ({ t, i18n, ...props }) => {
  const imageList = [
    Slide01Img,
    Slide02Img,
    Slide03Img,
    Slide04Img,
    Slide05Img,
    Slide06Img
  ];

  return (
    <Background>
      <StyledProperty>
        <Row>
          <StyledCol col={12}>
            <TitleTag borderPosition="bottom" borderAlign="center" textAlign="center">{t('property:title')}</TitleTag>
          </StyledCol>
        </Row>
        <Row>
          <StyledCol col={12} md={5} lg={5.6}>
            <PhotoDisplay>
              <Photo />
              <ObjectBack />
            </PhotoDisplay>
          </StyledCol>
          <StyledCol col={12} md={7} lg={6.4}>
            <AboutTitle>{t('property:vitaTower')}</AboutTitle>
            <Paragraph>{t('property:infoA')}</Paragraph>
            <DisplaySliderContainer>
              <SliderContainer>
                <Slider imageList={imageList} outlinePagination autoplay time={5000} limit={6} />
              </SliderContainer>
              <ObjectBackClone />
            </DisplaySliderContainer>
          </StyledCol>
        </Row>
        <Row>
          <StyledCol col={12}>
            <Paragraph>{t('property:infoB')}</Paragraph>
            <Paragraph>{t('property:infoC')}</Paragraph>
          </StyledCol>
        </Row>
      </StyledProperty>
    </Background>
  );
};

Property.propTypes = {};

Property.defaultProps = {};

export default withTranslation(['global', 'property'])(Property);
