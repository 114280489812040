import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import breakpoint from 'styled-components-breakpoint';
import { blues } from '../../core/colors';

const StyledMobileMenuButton = styled.div`
  margin: 0;
  padding: 0;
  width: 45px;
  height: 45px;
  font-size: 0;
  text-indent: -9999px;
  appearance: none;
  box-shadow: none;
  border-radius: none;
  border: none;
  cursor: pointer;
  transition: background 0.3s;
  top: 0;
  right: 0;
  z-index: 99;
  position: relative;
  &.active {
    span {
      background: none;
      &:before {
        top: 0;
        transform: rotate(45deg);
      }
      &:after {
        bottom: 0;
        transform: rotate(-45deg);
      }
    }
  }
  span {
    pointer-events: none;
    display: block;
    position: absolute;
    top: 50%;
    border-radius: 2px;
    transform: translateY(-50%);
    left: 6.6px;
    right: 6.6px;
    height: 3.5px;
    background: ${blues[1].main};
    &:before, &:after {
      position: absolute;
      display: block;
      left: 0;
      width: 100%;
      height: 3.5px;
      background: ${blues[1].main};
      content: "";
      border-radius: 2px;
      transition-duration: 0.3s, 0.3s;
    }
    &:before {
      top: -8px;
      transition-property: top, transform;
    }
    &:after {
      bottom: -8px;
      transition-property: bottom, transform;
    }
  }
  ${breakpoint('lg')`
    display: none;
  `}
`;
const MobileMenuButton = ({ onClickFn, thisRef }) => {

  return (
    <StyledMobileMenuButton onClick={() => onClickFn()} ref={thisRef}>
      <span></span>
    </StyledMobileMenuButton>
  );
};

MobileMenuButton.propTypes = {
  onClickFn: PropTypes.func,
  thisRef: PropTypes.object,
};

MobileMenuButton.defaultProps = {
  onClickFn: () => {},
  thisRef: {},
};

export default MobileMenuButton;
