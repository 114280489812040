import styled from 'styled-components';
import { Row } from 'styled-bootstrap-grid';
import breakpoint from 'styled-components-breakpoint';

const StyledRow = styled(Row)`
  ${breakpoint('md', 'lg')`
    margin-left: -50px;
    margin-right: -50px;
  `}
`;

export default StyledRow;
