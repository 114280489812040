import React from 'react';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import { Container, Row, Col } from '../../core/Grid';
import { withTranslation } from "react-i18next";
import TitleTag from '../../component/TitleTag';
import Background from '../../chapter/Background';
import Paragraph from '../../component/Paragraph';
import Button from '../../component/Button';
import { reds, greys } from '../../core/colors';
import BackImg from '../../core/images/back.svg';

const ButtonContainer = styled.div`
  margin-top: 30px;
  text-align: center;
  ${breakpoint('lg')`
    margin-top: 60px;
  `}
`;

const StyledPrivacy = styled(Container)``;

const Privacy = ({ t, i18n, ...props }) => {

  return (
    <Background>
      <StyledPrivacy>
        <Row>
          <Col col={12}>
            <TitleTag borderPosition="bottom" borderAlign="center" textAlign="center">{t('header:privacy')}</TitleTag>
          </Col>
        </Row>
        <Row>
          <Col col={12}>
            <Paragraph>{t('privacy:text')}</Paragraph>
            <ButtonContainer>
              <Button
                mainColor={reds[2].main}
                hoverColor={reds[1].main}
                textColor={greys[1].main}
                textHoverColor={greys[1].main}
                icon={BackImg}
                onClick={() => {
                  window.history.back();
                  // setTimeout(() => {window.scrollTo(0, 0);}, 100)
                }}
              >
                {t('global:back')}
              </Button>
            </ButtonContainer>
          </Col>
        </Row>
      </StyledPrivacy>
    </Background>
  );
};

Privacy.propTypes = {};

Privacy.defaultProps = {};

export default withTranslation(['header', 'privacy'])(Privacy);
