import { rgba, darken, lighten } from 'polished';
import blues from './blues';
import greys from './greys';
import reds from './reds';
import greens from './greens';

const colors = {
  rgba,
  blues,
  greys,
  reds,
  greens,
};

export default colors;
export { rgba, darken, lighten };
export { blues };
export { greys };
export { reds };
export { greens };
