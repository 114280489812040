import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import breakpoint from 'styled-components-breakpoint';
import { withTranslation } from "react-i18next";
import { greys, reds } from '../../core/colors';
import ThumbnailContainer from '../../component/ThumbnailContainer';
import Background from './images/background.jpg';
import BackgroundParking from './images/background_parking.jpg';
import Button from '../../component/Button';

const Message = styled.div`
  font-size: 1.4rem;
  line-height: 38px;
  font-weight: bold;
  text-align: center;
  color: ${greys[1].main};
  ${breakpoint('md')`
    text-align: left;
  `}
  ${breakpoint('lg')`
    white-space: pre-line;
    text-align: right;
  `}
`;

const ButtonContainer = styled.div`
  text-align: center;
  pointer-events: auto;
  margin-top: 10px;
  a {
    width: auto;
    display: inline-block;
  }
  ${breakpoint('md')`
    text-align: right ;
  `}
  ${breakpoint('lg')`
    margin-top: 30px;
  `}
`;

const Inner = styled.div`
  width: inherit;
  height: inherit;
  position: relative;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  padding: 20px 10px;
  z-index: 2;
  pointer-events: none;
  ${breakpoint('md')`
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  `}
  ${breakpoint('lg')`
    padding: 30px;
  `}
`;

const InfoContainer = styled.div`
  position: relative;
  background-color: ${reds[2].main};
  min-height: 316px;
  display: flex;
  align-items: center;
  ${breakpoint('md')`
    max-width: 50%;
    min-height: auto;
    flex: 0 0 50%;
    &:before {
      content: '';
      width: 150px;
      z-index: 2;
      background-color: ${reds[2].main};
      height: 100%;
      position: absolute;
      left: -65px;
      transform: skew(-20deg, 0deg);
    }
  `}
`;

const BackgroundImages = styled.div`
  top: 0;
  left: 0;
  position: absolute;
  background-color: ${greys[2].main};
  background-image: url(${({ isParking }) => !isParking ? Background : BackgroundParking});
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
`;

const StyledZeroResult = styled.div`
  margin-top: 30px;
  border-radius: 15px;
  overflow: hidden;
  ${breakpoint('md')`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: stretch;
    .thumbnail-container {
      flex: 0 0 50%;
      max-width: 50%;
      display: flex;
      flex-direction: column;
      height: 350px;
    }
  `}
  ${breakpoint('lg')`
    .thumbnail-container {
      max-height: 350px;
    }
  `}
`;

const ZeroResult = ({ t, i18n, isParking, isAvailable }) => {
  return (
    <StyledZeroResult className="zero-result">
      <ThumbnailContainer>
        <BackgroundImages isParking={isParking} />
      </ThumbnailContainer>
      <InfoContainer>
        <Inner>
          <Message isParking={isParking}>
            {!isParking ? (
              t('lookForPremises')
            ) : (
              isAvailable ? (
                t('lookForParking')
              ) : (
                t('notAvailableParking')
              )
            )}
          </Message>
          <ButtonContainer>
            <Button
              component="a"
              link={`/${i18n.language}/contact`}
              linkSelf
              mainColor={reds[2].main}
              hoverColor={reds[1].main}
              textColor={greys[1].main}
              borderColor={greys[1].main}
              borderHoverColor={greys[1].main}
            >
              {t('contactUs')}
            </Button>
          </ButtonContainer>
        </Inner>
      </InfoContainer>
    </StyledZeroResult>
  );
};

ZeroResult.propTypes = {
  isParking: PropTypes.bool,
  isAvailable: PropTypes.bool,
};

ZeroResult.defaultProps = {
  isParking: false,
  isAvailable: false,
};

export default withTranslation('global')(ZeroResult);
