import React, { useRef, useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import { Container, Row, Col, Hidden } from '../../core/Grid';
import { withTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import TitleTag from '../../component/TitleTag';
import Background from '../../chapter/Background';
import Button from '../../component/Button';
import { rgba, reds, greys, blues, greens } from '../../core/colors';
import BackgroundImg from './images/background.jpg';
import LoadingIcon from './images/loading.svg';
import emailjs from 'emailjs-com';
import Popup from 'reactjs-popup';

const PopupButtonContainer = styled.div`
  margin-top: 30px;
  button {
    display: flex;
    width: auto;
    margin: 0 auto;
  }
`;

const PopupMsg = styled.div`
  font-size: 1.125rem;
  line-height: 35px;
  font-weight: bold;
  color: ${greys[4].main};
  text-align: center;
`;
const SuccessContainer = styled.div`
  width: 100%;
  font-size: 1.75rem;
  font-weight: bold;
  color: ${greys[1].main};
  text-align: center;
  padding: 55px;
  background-color: ${greens[1].main};
`;
const FailContainer = styled(SuccessContainer)`
  background-color: ${reds[1].main};
`;

const LoadingMsg = styled(PopupMsg)`
  margin-top: 20px;
`
const rotate = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`;
const LoadingSpin = styled.div`
  background-image: url(${LoadingIcon});
  background-position: center center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 61px;
  width: 61px;
  animation: ${rotate} 2s linear infinite;
`;
const LoadingContainer = styled.div`
  padding: 30px;
`;
const PopupContainer = styled.div`
  width: 300px;
  border-radius: 15px;
  overflow: hidden;
  min-height: 236px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${greys[1].main};
  box-shadow: 0 10px 40px 0 ${rgba(greys[10].main, 0.15)};
`;

const ErrorMsg = styled.div`
  margin-top: 10px;
  padding-left: 25px;
  color: ${greys[1].main};
  font-size: 1rem;
  font-weight: bold;
`;

const InputField = styled.input`
  height: 42px;
  border : ${({ isError }) => (isError ? `2px solid ${reds[2].main}` : '2px solid transparent')};
  ::-webkit-input-placeholder { /* Edge */
    line-height:normal !important;
    color: ${({ isError }) => (isError ? reds[2].main : greys[3].main)};
  }
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: ${({ isError }) => (isError ? reds[2].main : greys[3].main)};
  }
  ::placeholder {
    color: ${({ isError }) => (isError ? reds[2].main : greys[3].main)};
  }
`;
const StyledButton = styled(Button)``;
const ButtonContainer = styled.div`
  margin-top: 30px;
  ${breakpoint('md')`
    margin-top: 60px;
    text-align: center;
  `}
`;
const TextArea = styled.textarea`
  min-height: 126px;
  border : ${({ isError }) => (isError ? `2px solid ${reds[2].main}` : '2px solid transparent')};
  ::-webkit-input-placeholder { /* Edge */
    color: ${({ isError }) => (isError ? reds[2].main : greys[3].main)};
  }
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: ${({ isError }) => (isError ? reds[2].main : greys[3].main)};
  }
  ::placeholder {
    color: ${({ isError }) => (isError ? reds[2].main : greys[3].main)};
  }
`;

const InputContainer = styled.div`
  margin-top: 30px;
`;

const StyledCol = styled(Col)`
  ${breakpoint('xs', 'md')`
    margin-top: 30px;
  `}
`;

const StyledRow = styled(Row)`
  ${breakpoint('md')`
    margin-right: -20px;
    margin-left: -20px;
  `}
`;

const Form = styled.form`
  position: relative;
  z-index: 2;
  line-height: normal;
  display: flex;
  flex-direction: column;
  textarea, input {
    resize: none;
    width: 100%;
    outline: none;
    padding: 10px 8px 10px 25px;
    font-weight: bold;
    border-radius: 21px;
    font-size: 1rem;
    color: ${blues[1].main};
    -webkit-appearance: none;
  }
  ${breakpoint('lg')`
    max-width: 730px;
    margin: 0 auto;
  `}
`;

const FormContainer = styled.div`
  margin-top: 30px;
  padding: 30px 20px;
  overflow: hidden;
  border-radius: 15px;
  background-image: url(${BackgroundImg});
  background-position: center top;
  background-size: cover;
  background-repeat: no-repeat;
  height: inherit;
  width: inherit;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: ${rgba(blues[1].main, 0.9)};
  }
  ${breakpoint('lg')`
    padding: 70px 20px 60px 20px;
  `}
`;

const StyledContact = styled(Container)``;

const Contact = ({ t, i18n, ...props }) => {
  const { register, handleSubmit, errors, reset } = useForm();
  const [recaptchaToken, setRecaptchaToken] = useState('');
  const [recaptchaErrorMsg, setRecaptchaErrorMsg] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [submitStatus, setSubmitStatus] = useState('load');
  const closeModal = () => {
    setIsOpen(false);
    setSubmitStatus('load');
  }

  const formRef = useRef();
  const onSubmit = () => {
    if(formRef?.current) {
      setIsOpen(true);
      // eslint-disable-next-line no-undef
      grecaptcha.ready(async () => {
        const token = await window.grecaptcha.execute('6LdnIIglAAAAAMnutntKvS5njo6tH5HCXc8DnH-W', { action: 'submit' });
        setRecaptchaToken(token);

        const response = await fetch('/api/token.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: `recaptchaToken=${encodeURIComponent(token)}`,
        });

        // wait for response
        if (response.ok) {
          emailjs.sendForm('service_waagd45', 'template_k0o620u', formRef?.current, 'PD5o3p-xxXE6spn2A')
          .then((result) => {
            setSubmitStatus('success');
            reset();
          }, (error) => {
            setSubmitStatus('fail');
          });
        } else {
          // console.log('response', response, response.statusText);
          const errorResponse = await response.json();
          setSubmitStatus('nonHuman');
          setRecaptchaErrorMsg(errorResponse.message);
        }
      });
    }
  }

  return (
    <Background>
      <Popup
        open={isOpen}
        modal
        lockScroll
        closeOnDocumentClick={false}
        overlayStyle={{
          "background": rgba(greys[10].main, 0.15),
          "z-index": "10000"
        }}
      >
        <PopupContainer>
          {submitStatus === 'load' && (
            <LoadingContainer>
              <LoadingSpin />
              <LoadingMsg>{t('contact:loading')}</LoadingMsg>
            </LoadingContainer>
          )}
          {submitStatus === 'success' && (
            <>
              <SuccessContainer>{t('contact:success')}</SuccessContainer>
              <LoadingContainer>
                <PopupMsg>{t('contact:successMsg')}</PopupMsg>
                <PopupButtonContainer>
                  <StyledButton
                    mainColor={greys[1].main}
                    hoverColor={blues[1].main}
                    textColor={blues[1].main}
                    textHoverColor={greys[1].main}
                    borderColor={blues[1].main}
                    borderHoverColor={blues[1].main}
                    onClick={() => closeModal()}
                  >
                    {t('contact:ok')}
                  </StyledButton>
                </PopupButtonContainer>
              </LoadingContainer>
            </>
          )}
          {(submitStatus === 'fail' || submitStatus === 'nonHuman') && (
            <>
              <FailContainer>{t('contact:fail')}</FailContainer>
              <LoadingContainer>
                <PopupMsg>{submitStatus === 'nonHuman' ? recaptchaErrorMsg : t('contact:failMsg')}</PopupMsg>
                <PopupButtonContainer>
                  <StyledButton
                    mainColor={greys[1].main}
                    hoverColor={blues[1].main}
                    textColor={blues[1].main}
                    textHoverColor={greys[1].main}
                    borderColor={blues[1].main}
                    borderHoverColor={blues[1].main}
                    onClick={() => closeModal()}
                  >
                    {t('contact:try')}
                  </StyledButton>
                </PopupButtonContainer>
              </LoadingContainer>
            </>
          )}
        </PopupContainer>
      </Popup>
      <StyledContact>
        <Row>
          <Col col={12}>
            <TitleTag borderPosition="bottom" borderAlign="center" textAlign="center">{t('contact:title')}</TitleTag>
          </Col>
        </Row>
        <Row>
          <Col col={12}>
            <FormContainer>
              <Form onSubmit={handleSubmit(onSubmit)} ref={formRef}>
                <StyledRow>
                  <Col col={12} md={6}>
                    <InputField isError={errors.name} name="name" placeholder={t('contact:placeholder', { returnObjects: true })['name']} ref={register({ required: true })} />
                    {errors.name && (<ErrorMsg>{t('contact:error', { returnObjects: true })['empty']}</ErrorMsg>)}
                  </Col>
                  <StyledCol col={12} md={6}>
                    <InputField isError={errors.tel} type="number" name="tel" placeholder={t('contact:placeholder', { returnObjects: true })['tel']} ref={register({ required: true })} />
                    {errors.tel && (<ErrorMsg>{t('contact:error', { returnObjects: true })['empty']}</ErrorMsg>)}
                  </StyledCol>
                </StyledRow>
                <InputContainer>
                  <InputField isError={errors.company} name="company" placeholder={t('contact:placeholder', { returnObjects: true })['comName']} />
                  {/* {errors.company && (<ErrorMsg>{t('contact:error', { returnObjects: true })['empty']}</ErrorMsg>)} */}
                </InputContainer>
                <InputContainer>
                  <InputField isError={errors.email} name="email" placeholder={t('contact:placeholder', { returnObjects: true })['email']} ref={register({ required: true, pattern: /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/})} />
                  {errors.email?.type === "required" && (<ErrorMsg>{t('contact:error', { returnObjects: true })['empty']}</ErrorMsg>)}
                  {errors.email?.type === "pattern" && (<ErrorMsg>{t('contact:error', { returnObjects: true })['email']}</ErrorMsg>)}
                </InputContainer>
                <InputContainer>
                  <TextArea isError={errors.message} name="message" placeholder={t('contact:placeholder', { returnObjects: true })['msg']} ref={register({ required: true })} />
                  {errors.message && (<ErrorMsg>{t('contact:error', { returnObjects: true })['empty']}</ErrorMsg>)}
                </InputContainer>
                <ButtonContainer>
                  <StyledButton
                    mainColor={reds[1].mainColor}
                    textColor={greys[1].mainColor}
                    type="submit"
                  >
                    {t('contact:submit')}
                  </StyledButton>
                </ButtonContainer>
              </Form>
            </FormContainer>
          </Col>
        </Row>
      </StyledContact>
    </Background>
  );
};

Contact.propTypes = {};

Contact.defaultProps = {};

export default withTranslation(['global', 'contact'])(Contact);
