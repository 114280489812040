import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import breakpoint from 'styled-components-breakpoint';
import { greys, rgba } from '../../core/colors';
import { Container, Row, Col } from '../../core/Grid';
import banner375 from './images/banner-375.jpg';
import banner768 from './images/banner-768.jpg';
import banner1920 from './images/banner-1920.jpg';

const BannerSubTitle = styled.h2`
  margin-top: 35px;
  color: ${greys[1].main};
  font-weight: normal;
`;

const BannerTitle = styled.h1`
  color: ${greys[1].main};
`;

const StyledCol = styled(Col)`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  ${breakpoint('md')`
    text-align: center;
  `}
`;
const StyledRow = styled(Row)`
  height: 100%;
`;
const BannerContainer = styled(Container)`
  height: 100%;
  ${breakpoint('lg')`
    padding-top: 40px;
  `}
`;

const StyledBanner = styled.div`
  min-height: 580px;
  height: calc(100vh - 84px);
  width: 100%;
  z-index: 0;
  position: relative;
  background-repeat: no-repeat;
  background-color: ${greys[3].main};
  ${breakpoint('xs', 'md')`
    background-image: url(${banner375});
    background-size: cover;
    background-position: center center;
  `}
  ${breakpoint('md', 'lg')`
    background-image: url(${banner768});
    background-size: 110% auto;
    background-position: center -50px;
  `}
  ${breakpoint('lg')`
    background-image: url(${banner1920});
    background-size: 120% auto;
    background-position: left 0;
  `}
  ${breakpoint('xl')`
    background-position: left -250px;
  `}
  &:after {
    content: "";
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: -1;
    background-color: ${rgba(greys[10].main, 0.15)};
  }
  ${breakpoint('md')`
    height: 580px;
  `}
  ${breakpoint('lg')`
    height: 600px;
  `}
`;

const Banner = ({ title, subTitle, ...props }) => {
  return (
    <StyledBanner>
      <BannerContainer>
        <StyledRow>
          <StyledCol col={12}>
            <BannerTitle>{title}</BannerTitle>
            <BannerSubTitle>{subTitle}</BannerSubTitle>
          </StyledCol>
        </StyledRow>
      </BannerContainer>
    </StyledBanner>
  );
};

Banner.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
};

Banner.defaultProps = {
  title: "PING PING INVESTMENT COMPANY LIMITED",
  subTitle: "VITA TOWER MANAGEMENT COMPANY LIMITED",
};

export default Banner;
