import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import breakpoint from 'styled-components-breakpoint';
import backgroundImage from '../../core/images/building.jpg';

const StyledBackground = styled.div`
  position: relative;
  ${breakpoint('md')`
    min-height: 800px;
  `}
  ${breakpoint('lg')`
    padding-top: 40px;
    min-height: 1200px;
  `}
  &:before {
    z-index: -1;
    opacity: 0.15;
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    background-image: url(${backgroundImage});
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    height: 100vh;
    width: 100%;
    ${breakpoint('md')`
      background-position: center top;
    `}
    ${breakpoint('lg')`
      height: 100%;
      top: 40px;
      background-size: 100%;
    `}
  }
`;

const Background = ({ children, ...props }) => {

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <StyledBackground>
      {children}
    </StyledBackground>
  );
};

Background.propTypes = {
  children: PropTypes.node,
};

Background.defaultProps = {
  children: undefined,
};

export default Background;
