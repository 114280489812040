import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import breakpoint from 'styled-components-breakpoint';

const StyledThumbnailContainer = styled.div`
  width: 100%;
  padding-top: 100%;
  position: relative;
  ${breakpoint('md', 'lg')`
    max-width: 33.855vw;
    height: 33.855vw;
    padding-top: 0;
  `}
  ${breakpoint('lg')`
    z-index: 2;
    ${({ with3D }) => (with3D? `
      transition: transform 0.6s ease;
      transform: rotateY(0deg);
      transform-style: preserve-3d;
      backface-visibility: hidden;
    ` : `
      padding-top: 0;
      min-height: 350px;
    `)}
  `}
`;


const ThumbnailContainer = ({ children, with3D }) => {
  return (
    <StyledThumbnailContainer with3D={with3D} className="thumbnail-container">
      {children}
    </StyledThumbnailContainer>
  );
};

ThumbnailContainer.propTypes = {
  children: PropTypes.node,
  with3D: PropTypes.bool,
};

ThumbnailContainer.defaultProps = {
  children: undefined,
  with3D: false,
};

export default ThumbnailContainer;
