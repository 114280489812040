import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import breakpoint from 'styled-components-breakpoint';
import { reds, greys } from '../../core/colors';

const StyledTitleTag = styled.h2`
  ${({ borderPosition }) => (borderPosition === 'bottom' ? 'padding-bottom: 30px;' : 'padding-top: 30px;')}
  position: relative;
  color: ${({ textColor }) => (textColor)};
  ${({ textAlign }) => (textAlign ? `text-align: ${textAlign};` : '')};
  :after {
    content: "";
    width: 75px;
    position: absolute;
    height: 4px;
    ${({ borderPosition }) => (borderPosition === 'bottom' ? 'bottom: 0;' : 'top: 0;')}
    ${({ borderAlign }) => (borderAlign === 'left' ? 'left: 0;' : 'left: 50%; transform: translateX(-50%);')}
    border-bottom: 4px solid ${({ borderColor }) => (borderColor)};
  }
`;

const TitleTag = ({ children, borderPosition, borderAlign, borderColor, textColor, textAlign }) => (
  <StyledTitleTag
    className="page-title"
    borderPosition={borderPosition}
    borderAlign={borderAlign}
    borderColor={borderColor}
    textColor={textColor}
    textAlign={textAlign}
  >
    {children}
  </StyledTitleTag>
);

TitleTag.propTypes = {
  children: PropTypes.node,
  borderPosition: PropTypes.string,
  borderAlign: PropTypes.string,
  borderColor: PropTypes.string,
  textAlign: PropTypes.string,
  textColor: PropTypes.string,
};

TitleTag.defaultProps = {
  children: undefined,
  borderPosition: 'bottom',
  borderAlign: 'left',
  borderColor: reds[2].main,
  textAlign: undefined,
  textColor: greys[10].main,
};

export default TitleTag;
