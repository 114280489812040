import React from 'react';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import { Col as PackageCol } from 'styled-bootstrap-grid';
import PropTypes from 'prop-types';

const StyledCol = styled(PackageCol)`
${({ xsNoGutter }) => xsNoGutter && breakpoint('xs', 'sm')`
  padding-right: 0;
  padding-left: 0;
`}
  ${({ smNoGutter }) => smNoGutter && breakpoint('sm', 'md')`
    padding-right: 0;
    padding-left: 0;
  `}
  ${({ mdNoGutter }) => mdNoGutter && breakpoint('md', 'lg')`
    padding-right: 0;
    padding-left: 0;
  `}
  ${({ lgNoGutter }) => lgNoGutter && breakpoint('lg', 'xl')`
    padding-right: 0;
    padding-left: 0;
  `}
  ${({ xlNoGutter }) => xlNoGutter && breakpoint('xl')`
    padding-right: 0;
    padding-left: 0;
  `}
`;

const Col = ({ col, xsNoGutter, smNoGutter, mdNoGutter, lgNoGutter, xlNoGutter, ...props }) => (
  <StyledCol
    col={col}
    xsNoGutter={xsNoGutter}
    smNoGutter={smNoGutter}
    mdNoGutter={mdNoGutter}
    lgNoGutter={lgNoGutter}
    xlNoGutter={xlNoGutter}
    {...props}
  />
);

Col.propTypes = {
  col: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]),
  xsNoGutter: PropTypes.bool,
  smNoGutter: PropTypes.bool,
  mdNoGutter: PropTypes.bool,
  lgNoGutter: PropTypes.bool,
  xlNoGutter: PropTypes.bool,
};

Col.defaultProps = {
  col: true,
  xsNoGutter: false,
  smNoGutter: false,
  mdNoGutter: false,
  lgNoGutter: false,
  xlNoGutter: false,
};

export default Col;
