import React, { createContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";

const TranslationProvider = ({ theme: propsTheme, langCode, children }) => {
  return (
    <I18nextProvider i18n={i18n}>
      {children}
    </I18nextProvider>
  );
};

TranslationProvider.propTypes = {
  langCode: PropTypes.string,
  children: PropTypes.node.isRequired,
};

TranslationProvider.defaultProps = {
  langCode: 'en',
};


export default TranslationProvider;