import React from 'react';
import PropTypes from 'prop-types';
import Swiper from 'react-id-swiper';
import SwiperContainer from './components/SwipeContainer';
import SwipeSlide from './components/SwipeSlide';

const SwiperPlay = ({
  imageList, limit, autoplay, time, ...props
}) => {
  const params = {
    slidesPerView: 1,
    spaceBetween: 0,
    preloadImages: false,
    lazy: {
      loadPrevNext: true,
    },
    ...(imageList && imageList.length > 1 ? {
      autoplay: autoplay ? {
        delay: time,
      } : false,
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true,
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      loop: true,
    } : {
      allowTouchMove: false,
      preventClicks: false,
      pagination: {
        el: '.swiper-pagination.swiper-disable-pagination',
      },
    }),
  };
  return (
    <SwiperContainer {...props}>
      <Swiper {...params}>
        {imageList && imageList.length > 0 && imageList.slice(0, limit).map((v, i) => (
          <SwipeSlide
            key={`SwipeSlide_${i}`}
            imageUrl={v}
          />
        ))}
      </Swiper>
    </SwiperContainer>
  );
};

SwiperPlay.propTypes = {
  limit: PropTypes.number,
  imageList: PropTypes.array,
  autoplay: PropTypes.bool,
  time: PropTypes.number,
};

SwiperPlay.defaultProps = {
  limit: 3,
  imageList: [],
  autoplay: false,
  time: 3000,
};

export default SwiperPlay;
