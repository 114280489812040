import React, { useContext } from 'react';
import styled, { ThemeContext, css } from 'styled-components';
import PropTypes from 'prop-types';
import breakpoint from 'styled-components-breakpoint';
import { withTranslation } from "react-i18next";
import { greys, reds, blues, rgba } from '../../core/colors';
import Slider from '../../component/Slider';
import Button from '../../component/Button';
import PDFImage from './images/pdf.svg';
import ThumbnailContainer from '../../component/ThumbnailContainer';

const Inner = styled.div`
  width: inherit;
  height: inherit;
  position: relative;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  padding: 20px 10px;
  z-index: 2;
  ${breakpoint('md')`
    ${({ isGrid }) => (!isGrid ? `padding: 30px;` : ``)};
  `}
`;

const InnerClone = styled(Inner)`
  &&& {
    pointer-events: none;
    position: absolute;
    padding: 0;
  }
`;

const ButtonContainer = styled.div`
  text-align: center;
  pointer-events: auto;
  a {
    width: auto;
    display: inline-block;
    ${breakpoint('md')`
      ${({ isGrid }) => (!isGrid ? `
        position: absolute;
        right: 15px;
        bottom: 15px;
        display: block;
        text-align: left;
      ` : ``)};
    `}
    ${breakpoint('lg')`
      ${({ isGrid }) => (!isGrid ? `
        right: 30px;
        bottom: 30px;
      ` : ``)};
    `}
  }
`;

const Item = styled.div`
  font-size: 1.25rem;
  line-height: 35px;
  text-align: center;
  font-weight: bold;
  color: ${greys[4].main};
  ${breakpoint('md')`
    ${({ isGrid }) => (!isGrid ? `
      text-align: left;
      padding-left: 10px;
    ` : ``)};
  `}
`;

const Tag = styled.div`
  font-size: 1.75rem;
  line-height: 38px;
  text-align: center;
  color: ${blues[1].main};
  font-weight: bold;
  ${breakpoint('md')`
    ${({ isGrid }) => (!isGrid ? `
      text-align: left;
    ` : ``)};
  `}
`;

const InfoItem = styled.div`
  margin-bottom: 10px;
  &:last-child {
    margin-bottom: 0;
  }
  ${breakpoint('lg')`
    ${({ isGrid, last }) => (!isGrid ? `
      margin-bottom: 30px;
      ${last ? 'margin-bottom: 11px;': ''};
    ` : ``)};
  `}
`;

const PDFIcon = styled.div`
  background-image: url(${PDFImage});
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center center;
  height: 59px;
  width: 59px;
  margin: 0 auto;
  margin-bottom: 10px;
  ${breakpoint('md')`
    ${({ isGrid }) => (!isGrid ? `
      position: absolute;
      top: 15px;
      right: 15px;
    ` : ``)};
  `}
  ${breakpoint('lg')`
    ${({ isGrid }) => (!isGrid ? `
      top: 30px;
      right: 30px;
    ` : ``)};
  `}
`;

const Address = styled.h3`
  z-index: 3;
  text-align: center;
  position: absolute;
  display: flex;
  height: 100%;
  width: 100%;
  padding: 42px;
  color: ${greys[1].main};
  justify-content: center;
  align-items: center;
`;

const InfoContainer = styled.div`
  position: relative;
  background-color: ${greys[1].main};
  ${breakpoint('md')`
     ${({ isGrid }) => (!isGrid ? `width: 100%;`: ``)};
  `}
`;

const SliderContainer = styled.div`
  overflow: hidden;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  .swiper-container {
    border-radius: 15px 15px 0 0;
  }
  ${breakpoint('md')`
    .swiper-container {
      border-radius: 15px 0 0 15px;
    }
  `}
  ${breakpoint('lg')`
    ${({ isGrid }) => (!isGrid ? `
      .swiper-button-prev,
      .swiper-button-next {
        display: none;
      }
      &:hover {
        .swiper-button-prev,
        .swiper-button-next {
          display: flex;
        }
      }
    ` : `
      .swiper-container {
        border-radius: 15px 15px 0 0;
      }
    `)}
  `}
`;

const StyledPropertyCard = styled.div`
  margin-top: 30px;
  box-shadow: 0 1px 40px 0 ${rgba(greys[10].main, 0.15)};
  border-radius: 15px;
  overflow: hidden;
  &:hover {
    box-shadow: 0 10px 40px 0 ${rgba(greys[10].main, 0.35)};
  }
  ${breakpoint('md')`
    ${({ isGrid }) => (!isGrid ? `
      display: flex;
      min-height: 250px;
      .thumbnail-container {
        padding-top: 0;
        max-width: 260px;
        height: auto;
        img {
          object-fit: cover;
        }
      }
    ` : ``)}
  `}
  ${breakpoint('lg')`
    margin-top: 60px;
    ${({ isGrid }) => (!isGrid ? `
      .thumbnail-container {
        max-width: 350px;
        height: auto;
      }
    ` : ``)}
  `}
`;

const Property = ({ t, i18n, data, type, isGrid, pageNumber, ...props }) => {
  const { isLgUp } = useContext(ThemeContext);
  const { language } = i18n;

  return (
    <StyledPropertyCard className="property-card" isGrid={isGrid}>
      {data?.thumbnail && data?.thumbnail.length > 0 && (
        <>
          <ThumbnailContainer with3D>
            <InnerClone>
              {data?.[language]?.address && (<Address>{data[language].address}</Address>)}
            </InnerClone>
            <SliderContainer isGrid={isGrid}>
              <Slider imageList={data?.thumbnail} limit={3} />
            </SliderContainer>
          </ThumbnailContainer>
          <InfoContainer>
            <Inner className="Inner">
              {data?.pdf && type === 'lettablePremises' && (<PDFIcon isGrid={isGrid} />)}
              {data?.[language]?.uses && type === 'lettablePremises' && (
                <InfoItem>
                  <Tag isGrid={isGrid}>{t('leasing:uses')}</Tag>
                  {data?.[language]?.uses && (<Item isGrid={isGrid}>{data[language].uses}</Item>)}
                </InfoItem>
              )}
              {data?.area && type === 'lettablePremises' && (
                <InfoItem>
                  <Tag isGrid={isGrid}>{t('leasing:grossArea')}</Tag>
                  {data?.area && (<Item isGrid={isGrid}>{t('leasing:area', { area: Number(data.area).toLocaleString('zh-HK') })}</Item>)}
                </InfoItem>
              )}
              {data?.[language].date && (
                <InfoItem last>
                  <Tag isGrid={isGrid}>{t('leasing:date')}</Tag>
                  <Item isGrid={isGrid}>{data?.[language]?.date}</Item>
                </InfoItem>
              )}
              <ButtonContainer isGrid={isGrid}>
                <Button
                  component="a"
                  link={`/${i18n.language}/detail/${type}/${data?.id}${(pageNumber > 1 ? '?page='+pageNumber : '')}`}
                  linkSelf
                  mainColor={greys[1].main}
                  hoverColor={blues[1].main}
                  textColor={blues[1].main}
                  textHoverColor={greys[1].main}
                  borderColor={blues[1].main}
                  borderHoverColor={blues[1].main}
                >
                  {t('global:detail')}
                </Button>
              </ButtonContainer>
            </Inner>
          </InfoContainer>
        </>
      )}
    </StyledPropertyCard>
  );
};

Property.propTypes = {
  data: PropTypes.object,
  type: PropTypes.string,
  isGrid: PropTypes.bool,
  pageNumber: PropTypes.number,
};

Property.defaultProps = {
  data: {},
  type: undefined,
  isGrid: false,
  pageNumber: null,
};

export default withTranslation(['global', 'leasing'])(Property);
