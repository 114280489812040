import React from 'react';
import styled, { ThemeContext } from 'styled-components';
import PropTypes from 'prop-types';
import breakpoint from 'styled-components-breakpoint';
import { greys, reds, rgba } from '../../core/colors';
// import ImageGallery from 'react-image-gallery';
import ImageGallery from './components/ImageGallery';
import GalleryContainer from './components/GalleryContainer';
import ArrowIcon from './images/arrow.svg';

const ArrowButton = styled.div`
  height: 46px;
  width: 46px;
  background-color: ${reds[2].main};
  border-radius: 50%;
  position: absolute;
  z-index: 2;
  background-image: url(${ArrowIcon});
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center center;
  cursor: pointer;
  transition: background 0.2s ease-in;
  &:disabled {
    visibility: hidden;
  }
  &:hover, &:focus, &:active {
    background-color: ${reds[1].main};
  }
  ${breakpoint('xs', 'md')`
    top: 50%;
    ${({ left }) => (left ? `left: 20px;` : `right: 20px;`)};
    ${({ left }) => (left ? `
      transform: translateY(-50%) rotate(-90deg);
    ` : `
      transform: translateY(-50%) rotate(90deg);
    `)};
  `}

  ${breakpoint('md', 'lg')`
    bottom: 0;
    ${({ left }) => (left ? `left: 0;` : `right: 0;`)};
    ${({ left }) => (left ? `
      transform: translateY(calc(((46px + 37.33vw) / 2) + 30px)) rotate(-90deg);
    ` : `
      transform: translateY(calc(((46px + 37.33vw) / 2) + 30px)) rotate(90deg);
    `)};
    
  `}
  ${breakpoint('md', 'lg')`
    ${({ left }) => (left ? `
      transform: translateY(calc(((46px + 23.6vw) / 2) + 30px)) rotate(-90deg);
    ` : `
      transform: translateY(calc(((46px + 23.6vw) / 2) + 30px)) rotate(90deg);
    `)};
  `}
  ${breakpoint('lg')`
    right: 0;
    ${({ left }) => (left ? `
      top: 0;
      transform: translateX(calc(((46px + 206px) / 2) + 30px));
    ` : `
      bottom: 0;
      transform: translateX(calc(((46px + 206px) / 2) + 30px)) rotate(180deg);
    `)};
  `}
`;

const StyledGallery = styled.div`
  ${({ thumbnailDisplay }) => (!thumbnailDisplay ? `
    .image-gallery-thumbnails-wrapper {
      display: none !important;
    }
    .image-gallery-slide-wrapper {
      width: 100% !important;
    }
  ` : '')}
`;

const Gallery = ({ images, ...props }) => {
  const { isLgUp } = React.useContext(ThemeContext);
  const mapImages = [];
  images.forEach((item) => {
    const thisObj = {
      original: item,
      thumbnail: item
    }
    mapImages.push(thisObj);
  })

  const renderLeftNav = (onClick, disabled) => {
    return (
      <ArrowButton
        className='image-gallery-custom-left-nav'
        left
        disabled={disabled}
        onClick={onClick} />
    )
  } 
  const renderRightNav = (onClick, disabled) => {
    return (
      <ArrowButton
        className='image-gallery-custom-right-nav'
        right
        disabled={disabled}
        onClick={onClick} />
    )
  } 

  return (
    <GalleryContainer>
      <StyledGallery {...props} thumbnailDisplay={mapImages.length > 1}>
        <ImageGallery
          items={mapImages}
          infinite={false}
          showFullscreenButton={false}
          showPlayButton={false}
          showBullets={false}
          renderLeftNav={renderLeftNav}
          renderRightNav={renderRightNav}
          thumbnailPosition={isLgUp ? "right" : "bottom"}
        />
      </StyledGallery>
    </GalleryContainer>
  )
};

Gallery.propTypes = {
  images: PropTypes.array,
};

Gallery.defaultProps = {
  images: [],
};

export default Gallery;
