import React from 'react';
import styled, { ThemeContext } from 'styled-components';
import PropTypes from 'prop-types';
import LazyLoad from 'react-lazyload';
import breakpoint from 'styled-components-breakpoint';
import { withTranslation } from "react-i18next";
import axios from 'axios';
import { reds, greys } from '../../../../core/colors';
import { Container, Row, Col } from '../../../../core/Grid';
import TitleTag from '../../../../component/TitleTag';
import Button from '../../../../component/Button';
import LandingProperty from '../../../../card/LandingProperty';
import ZeroResult from '../../../../card/ZeroResult';
import backgroundImage from '../../../../core/images/building.jpg';

const StyledCol = styled(Col)`
  ${breakpoint('lg')`
    &:first-child {
      padding-right: 15px;
    }
    &:nth-child(2) {
      padding-left: 15px;
      padding-right: 15px;
    }
    &:last-child {
      padding-left: 15px;
    }
  `}
`

const ButtonContainer = styled.div`
  margin-top: 30px;
  text-align: center;
`;

const PropertyCardContainer = styled.div`
  width: 100%;
  ${breakpoint('lg')`
    display: flex;
    margin-top: 30px;
  `}
`;

const StyledLeasing = styled.div`
  position: relative;
  &:before {
    opacity: 0.15;
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    background-image: url(${backgroundImage});
    background-size: cover;
    background-position: 45% top;
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
    ${breakpoint('lg')`
      background-position: center top;
    `}
    ${breakpoint('lg')`
      background-size: 100%;
    `}
  }
`;

const Leasing = ({ t, i18n }) => {
  const [lettablePremisesData, setLettablePremisesData] = React.useState();

  // React.useEffect(() => {
  //   axios.get(`../data/${i18n.language}/lettablepremises.json`)
  //   .then(res => {
  //     const persons = res.data;
  //     setLettablePremisesData(persons);
  //   })
  // }, []);
  React.useEffect(() => {
    axios.get(`../data/lettablepremises.json?timestamp=${new Date().getTime()}`)
    .then(res => {
      const responseData = res.data;
      setLettablePremisesData(responseData);
    })
  }, []);

  return (
    <StyledLeasing>
      <Container>
        <Row>
          <StyledCol col={12}>
            <TitleTag borderPosition="bottom" borderAlign="center" textAlign={"center"}>{t('home:leasingNow', { returnObjects: true })['title']}</TitleTag>
          </StyledCol>
          <PropertyCardContainer>
            {(lettablePremisesData && lettablePremisesData?.data && lettablePremisesData?.data.length > 0) ? (
              lettablePremisesData.data.slice(0, 3).map((v, i) => (
                <StyledCol col={12} lg={4} key={`image_${i}`}>
                  <LandingProperty data={v} type={lettablePremisesData?.type} typeName={lettablePremisesData?.name?.[i18n.language]}/>
                </StyledCol>
              ))
            ) : (
              <StyledCol col={12}>
                <ZeroResult />
              </StyledCol>
            )}
            
          </PropertyCardContainer>
          <StyledCol col={12}>
            <ButtonContainer>
              <Button
                component="a"
                link={`/${i18n.language}/leasing/lettablePremises`}
                linkSelf
                mainColor={reds[2].main}
                hoverColor={reds[1].main}
                textColor={greys[1].main}
              >
                {t('global:learnMore')}
              </Button>
            </ButtonContainer>
          </StyledCol>
        </Row>
      </Container>
    </StyledLeasing>
  )
};

Leasing.propTypes = {};

Leasing.defaultProps = {};

export default withTranslation(['global', 'home'])(Leasing);
