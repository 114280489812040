import React from 'react';
import styled, { ThemeContext } from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import axios from 'axios';
import { Container, Row, Col } from '../../core/Grid';
import { withTranslation } from "react-i18next";
import Background from '../../chapter/Background';
import Gallery from '../../chapter/Gallery';
import Button from '../../component/Button';
import { rgba, greys, reds, blues } from '../../core/colors';
import Paragraph from '../../component/Paragraph';
import BackImg from '../../core/images/back.svg';
import PeopleImg from './images/user.svg';

const RemarkContainer = styled.div`
  margin-top: 30px;

`;

const Item = styled.div`
  font-size: 1.25rem;
  line-height: 35px;
  text-align: center;
  font-weight: bold;
  color: ${greys[4].main};
  ${breakpoint('md')`
    text-align: left;
    padding-left: 11px;
  `}
`;

const Tag = styled.div`
  font-size: 1.75rem;
  line-height: 38px;
  text-align: center;
  color: ${blues[1].main};
  font-weight: bold;
  ${breakpoint('md')`
    text-align: left;
  `}
`;

const RemarkTag = styled(Tag)`
  text-align: left;
`;

const InfoItem = styled.div`
  margin-bottom: 10px;
  &:last-child {
    margin-bottom: 0;
  }
  ${breakpoint('md')`
    margin-bottom: 45px;
  `}
  ${breakpoint('lg')`
    margin-bottom: 15px;
    &:nth-child(n+2) {
      margin-left: 60px;
    }
  `}
`;

const InfoAreaContainer = styled.div`
  margin-top: 30px;
  padding: 30px 15px;
  background-color: ${greys[1].main};
  border-radius: 15px;
  box-shadow: 0 1px 40px 0 ${rgba(greys[10].main, 0.15)};
  ${breakpoint('md')`
    display: flex;
    flex-wrap: wrap;
    padding: 45px 30px;
    justify-content: space-between;
  `}
  ${breakpoint('lg')`
    align-items: center;
    justify-content: flex-start;
  `}
`;

const UseFor = styled.div`
  margin-top: 15px;
  font-size: 1.25rem;
  line-height: 35px;
  color: ${greys[4].main};
  position: relative;
  font-weight: bold;
  padding-left: 50px;
  display: flex;
  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    background-image: url(${PeopleImg});
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center center;
    width: 42px;
    height: 42px;
  }
  ${breakpoint('md')`
    margin-top: 30px;
  `}
`;

const DetailType = styled.div`
  font-size: 1rem;
  line-height: 22px;
  font-weight: bold;
  margin-top: 15px;
  padding: 10px 24px;
  background-color: ${reds[2].main};
  border-radius: 21px;
  text-align: center;
  color: ${greys[1].main};
  ${breakpoint('md')`
    margin-top: 0;
    margin-left: 15px;
  `}
`;

const DetailAddress = styled.div`
  font-size: 1.75rem;
  font-weight: bold;
  line-height: 38px;
  margin-top: 15px;
  ${breakpoint('md')`
    margin-top: 0;
  `}
`;

const DetailAddressTypeContainer = styled.div`
  ${breakpoint('md')`
    display: flex;
    margin-top: 30px;
  `}
`;

const ButtonContainer = styled.div``;
const DownloadContainer = styled.div`
  text-align: center;
  a {
    display: inline-block;
    width: auto;
  }
  ${breakpoint('md')`
    width: 100%;
  `}
  ${breakpoint('lg')`
    width: auto;
    margin-left: auto;
  `}
`;

const StyledDetail = styled(Container)``;

const Detail = ({ t, i18n, location, match, ...props }) => {
  // console.log('match', match);
  const [currentData, setCurrentData ] = React.useState();
  const [currentType, setCurrentType] = React.useState();
  const [currentId, setCurrentId] = React.useState();
  const previousPage = (location?.search) ? Number(location?.search.split('?page=')[1]) : 1;
  const { language } = i18n;

  React.useEffect(() => {
    if(match?.params && match?.params?.type) {
      setCurrentType(match.params.type);
    }
    if(match?.params && match?.params?.id) {
      setCurrentId(match.params.id);
    }
  }, [match]);

  React.useEffect(() => {
    if(currentId && currentType) {
      axios.get(`../../../../data/${currentType.toLowerCase()}.json`)
      .then(res => {
        const thisData = res.data?.data.filter(o=>Object.values(o).includes(currentId));
        if(thisData && thisData[0]) setCurrentData(thisData[0]);
      })
    }
  }, [currentId, currentType]);

  // React.useEffect(() => {
  //   console.log(currentData);
  // }, [currentData]);

  return (
    <Background>
      <StyledDetail>
        <Row>
          <Col col={12}>
            <ButtonContainer>
              <Button
                component="a"
                link={`/${language}/leasing/${currentType}${(previousPage > 1 ? '?page='+previousPage : '')}`}
                linkSelf
                mainColor={reds[2].main}
                hoverColor={reds[1].main}
                textColor={greys[1].main}
                textHoverColor={greys[1].main}
                icon={BackImg}
              >
                {t('global:back')}
              </Button>
            </ButtonContainer>
          </Col>
        </Row>
        <Row>
          <Col col={12}>
            <DetailAddressTypeContainer>
              {currentData?.[language]?.address && (<DetailAddress>{currentData[language].address}</DetailAddress>)}
              {currentType && (<DetailType>{t(`leasing:${currentType}`)}</DetailType>)}
            </DetailAddressTypeContainer>
          </Col>
        </Row>
        <Row>
          <Col col={12}>
            {currentData?.[language]?.uses && (<UseFor>{currentData[language].uses}</UseFor>)}
          </Col>
        </Row>
        <Row>
          <Col col={12}>
            {currentData?.photo && currentData.photo.length > 0 && (
              <Gallery images={currentData.photo} />
            )}
          </Col>
        </Row>
        <Row>
          <Col col={12}>
            <InfoAreaContainer>
              {currentData?.area && (
                <InfoItem>
                  <Tag>{t('leasing:grossArea')}</Tag>
                  <Item>{t('leasing:area', { area: Number(currentData.area).toLocaleString('zh-HK') })}</Item>
                </InfoItem>
              )}
              {currentData?.[language]?.date && (
                <InfoItem>
                  <Tag>{t('leasing:date')}</Tag>
                  <Item>{currentData[language].date}</Item>
                </InfoItem>
              )}
              {currentData?.pdf && (
                <DownloadContainer>
                  <Button
                    component="a"
                    link={currentData.pdf}
                    mainColor={reds[2].main}
                    hoverColor={reds[1].main}
                    textColor={greys[1].main}
                    textHoverColor={greys[1].main}
                  >
                    {t('global:downloadPdf')}
                  </Button>
                </DownloadContainer>
              )}
            </InfoAreaContainer>
          </Col>
        </Row>
        <Row>
          <Col col={12}>
            <RemarkContainer>
              {currentData?.[language]?.remark && (
                <>
                  <RemarkTag>{t('leasing:remark')}</RemarkTag>
                  <Paragraph>{currentData[language].remark}</Paragraph>
                </>
              )}
            </RemarkContainer>
          </Col>
        </Row>
      </StyledDetail>
    </Background>
  );
};

Detail.propTypes = {};

Detail.defaultProps = {};

export default withTranslation(['global', 'leasing'])(Detail);
