import React from 'react';
import styled, { ThemeContext } from 'styled-components';
import PropTypes from 'prop-types';
import LazyLoad from 'react-lazyload';
import breakpoint from 'styled-components-breakpoint';
import { withTranslation } from "react-i18next";
import { rgba, greys, blues } from '../../../../core/colors';
import { Container, Row, Col } from '../../../../core/Grid';
import TitleTag from '../../../../component/TitleTag';
import Paragraph from '../../../../component/Paragraph';
import Button from '../../../../component/Button';
import SendIcon from './images/send.svg';
import SendHoverIcon from './images/send_white.svg';
import AboutImg from '../About/images/about.jpg';

const ButtonContainer = styled.div`
  margin-top: 30px;
  text-align: center;
  ${breakpoint('lg')`
    text-align: right;
    margin-top: 0;
  `}
`;

const ParagraphContainer = styled.div`
  ${breakpoint('lg')`
    max-width: 555px;
  `}
`;

const StyledCol = styled(Col)`
  ${breakpoint('md', 'lg')`
    padding-left: 50px;
    padding-right: 50px;
  `}
  ${breakpoint('lg')`
    &:last-child {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  `}
`


const StyledContact = styled.div`
  background-image: url(${AboutImg});
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  height: inherit;
  width: inherit;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: ${rgba(blues[1].main, 0.9)};
  }
`;

const Contact = ({ t, i18n }) => {
  const { isLgUp } = React.useContext(ThemeContext);
  return (
    <StyledContact>
      <Container>
        <Row>
          <StyledCol col={12} lg={9}>
            <TitleTag
              borderPosition="top"
              borderAlign={isLgUp ? "left" : "center"}
              borderColor={greys[1].main}
              textAlign={isLgUp ? "left" : "center"}
              textColor={greys[1].main}
            >
              {t('home:contact', { returnObjects: true })['title']}
            </TitleTag>
            <ParagraphContainer>
              <Paragraph textAlign={isLgUp ? "left" : "center"} textColor={greys[1].main}>{t('home:contact', { returnObjects: true })['description']}</Paragraph>
            </ParagraphContainer>
          </StyledCol>
          <StyledCol col={12} lg={3}>
            <ButtonContainer>
              <Button
                component="a"
                link={`/${i18n.language}/contact`}
                linkSelf
                mainColor={greys[1].main}
                hoverColor={blues[1].main}
                textColor={blues[1].main}
                textHoverColor={greys[1].main}
                icon={SendIcon}
                iconHover={SendHoverIcon}
                borderHoverColor={greys[1].main}
              >
                {t('global:contactUs')}
              </Button>
            </ButtonContainer>
          </StyledCol>
        </Row>
      </Container>
    </StyledContact>
  )
};

Contact.propTypes = {};

Contact.defaultProps = {};

export default withTranslation(['global', 'home'])(Contact);
