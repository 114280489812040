import React from 'react';
import { Route, Link, NavLink } from 'react-router-dom'
import { withTranslation } from "react-i18next";
import PropTypes from 'prop-types';
import Header from './chapter/Header';
import Footer from './chapter/Footer';
import Home from './pages/Home';
import Leasing from './pages/Leasing';
import About from './pages/About';
import Property from './pages/Property';
import Privacy from './pages/Privacy';
import Disclaimer from './pages/Disclaimer';
import Detail from './pages/Detail';
import Contact from './pages/Contact';

const PingPingApp = ({ t, i18n, type, match, location, ...props }) => {
  // const { locale = 'en' } = router.query;
  const lang = i18n.language

  return (
    <div className="App">
      <Header match={match} location={location} />
      <>
        <Route path={`${match.url}/`} exact component={Home} />
        <Route path={`${match.url}/leasing`} exact component={Leasing} />
        <Route path={`${match.url}/leasing/:type`} exact component={Leasing} />
        <Route path={`${match.url}/about`} exact component={About} />
        <Route path={`${match.url}/property`} exact component={Property} />
        <Route path={`${match.url}/privacy`} exact component={Privacy} />
        <Route path={`${match.url}/disclaimer`} exact component={Disclaimer} />
        <Route path={`${match.url}/detail/:type/:id`} exact component={Detail} />
        <Route path={`${match.url}/contact`} exact component={Contact} />
      </>
      <Footer match={match} location={location} />
    </div>
  );
};

PingPingApp.propTypes = {
  t: PropTypes.func,
  i18n: PropTypes.object,
};

PingPingApp.defaultProps = {
  t: () => {},
  i18n: {},
};

export default withTranslation()(PingPingApp);
