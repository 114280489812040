import React from 'react';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

const Hidden = styled(({ children, className }) => React.Children.map(children, (child) => {
  const isValidElement = React.isValidElement(child);
  const element = isValidElement ? child : <span>{child}</span>;
  return React.cloneElement(element, {
    className: `${element.props.className || ''} ${className}`.trim(),
  });
}))`
  ${({ xs }) => xs && breakpoint('xs', 'sm')`
    display: none !important;
  `}
  ${({ sm }) => sm && breakpoint('sm', 'md')`
    display: none !important;
  `}
  ${({ md }) => md && breakpoint('md', 'lg')`
    display: none !important;
  `}
  ${({ lg }) => lg && breakpoint('lg', 'xl')`
    display: none !important;
  `}
  ${({ xl }) => xl && breakpoint('xl')`
    display: none !important;
  `}


  ${({ xsUp }) => xsUp && breakpoint('xs')`
    display: none !important;
  `}
  ${({ smUp }) => smUp && breakpoint('sm')`
    display: none !important;
  `}
  ${({ mdUp }) => mdUp && breakpoint('md')`
    display: none !important;
  `}
  ${({ lgUp }) => lgUp && breakpoint('lg')`
    display: none !important;
  `}
  ${({ xlUp }) => xlUp && breakpoint('xl')`
    display: none !important;
  `}


  ${({ xsDown }) => xsDown && breakpoint('xs', 'sm')`
    display: none !important;
  `}
  ${({ smDown }) => smDown && breakpoint('xs', 'md')`
    display: none !important;
  `}
  ${({ mdDown }) => mdDown && breakpoint('xs', 'lg')`
    display: none !important;
  `}
  ${({ lgDown }) => lgDown && breakpoint('xs', 'xl')`
    display: none !important;
  `}
  ${({ xlDown }) => xlDown && breakpoint('xs')`
    display: none !important;
  `}
`;

Hidden.propTypes = {
  children: (props, propName, componentName) => {
    // eslint-disable-next-line react/destructuring-assignment
    if (props[propName].$$typeof !== Symbol.for('react.element')) {
      return new Error(
        `Invalid prop \`${propName}\` supplied to \`${componentName}\`. Validation failed. \`${componentName}\` required a element children.`,
      );
    }
    // eslint-disable-next-line react/destructuring-assignment
    if (props[propName].type === React.Fragment) {
      return new Error(
        `Invalid prop \`${propName}\` supplied to \`${componentName}\`. Validation failed. Children of \`${componentName}\` cannot be a React Fragment.`,
      );
    }
    return null;
  },
};

export default Hidden;
