import React from 'react';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import { Container, Row, Col, Hidden } from '../../core/Grid';
import { withTranslation } from "react-i18next";
import TitleTag from '../../component/TitleTag';
import Background from '../../chapter/Background';
import { rgba, greys, blues } from '../../core/colors';
import Paragraph from '../../component/Paragraph';
import InfoMobileImg from './images/mobile_info.jpg';
import InfoDesktopImg from './images/desktop_info.jpg';

const AboutTitle = styled.h3`
  font-size: 2.1875rem;
  line-height: 47px;
  font-weight: bold;
  color: ${blues[1].main};
  margin-top: 30px;
  ${breakpoint('lg')`
    margin-top: 60px;
    font-size: 45px;
    line-height: 61px;
    &:first-of-type {
      margin-top: 90px;
    }
  `}
`;

const PhotoBack = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 20px;
  right: -10px;
  border-radius: 15px;
  overflow: hidden;
  background-color: ${blues[1].main};
  box-shadow: 0 1px 4px 0 ${rgba(greys[10].main, 0.15)};
  ${breakpoint('md')`
    right: 0;
    left: 20px;
    width: calc(100% - 20px);
  `}
`;

const Photo = styled.div`
  background-image: url(${InfoMobileImg});
  background-position: center bottom;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 15px;
  overflow: hidden;
  margin-left: -10px;
  height: 100%;
  width: 100%;
  position: relative;
  z-index: 2;
  background-color: ${greys[3].main};
  box-shadow: 0 1px 4px 0 ${rgba(greys[10].main, 0.15)};
  ${breakpoint('md')`
    margin-left: 0;
    background-image: url(${InfoDesktopImg});
  `}
`;

const PhotoDisplay = styled.div`
  height: 465px;
  width: 100%;
  margin-top: 30px;
  position: relative;
  margin-bottom: 20px;
  ${breakpoint('md')`
    padding-right: 20px;
  `}
  ${breakpoint('lg')`
    margin-top: 60px;
    height: 666px;
  `}
`;

const HiddenContainer = styled.div`
  ${breakpoint('md', 'lg')`
    ${({ hiddenMd }) => (hiddenMd ? 'display: none;' : '')};
  `}
  ${({ displayMd }) => (displayMd ? 'display: none;' : '')};
  ${breakpoint('md', 'lg')`
    ${({ displayMd }) => (displayMd ? 'display: block;' : '')};
  `}
`;

const StyledCol = styled(Col)`
`;

const StyledAbout = styled(Container)``;

const About = ({ t, i18n, ...props }) => {

  return (
    <Background>
      <StyledAbout>
          <Row>
            <StyledCol col={12}>
              <TitleTag borderPosition="bottom" borderAlign="center" textAlign="center">{t('home:about', { returnObjects: true })['title']}</TitleTag>
            </StyledCol>
          </Row>
          <Row>
            <StyledCol col={12} md={5} lg={5.6}>
              <PhotoDisplay>
                <Photo />
                <PhotoBack />
              </PhotoDisplay>
            </StyledCol>
            <StyledCol col={12} md={7} lg={6.4}>
              <AboutTitle>{t('global:title')}</AboutTitle>
              <Paragraph>{t('home:about', { returnObjects: true })['fullDescription']}</Paragraph>
              <HiddenContainer hiddenMd>
                <AboutTitle>{t('global:subTitle')}</AboutTitle>
                <Paragraph>{t('home:about', { returnObjects: true })['vitaFullDescription']}</Paragraph>
              </HiddenContainer>
            </StyledCol>
          </Row>
          <Row>
            <StyledCol col={12}>
              <HiddenContainer displayMd>
                <AboutTitle>{t('global:subTitle')}</AboutTitle>
                <Paragraph>{t('home:about', { returnObjects: true })['vitaFullDescription']}</Paragraph>
              </HiddenContainer>
            </StyledCol>
          </Row>
      </StyledAbout>
    </Background>
  );
};

About.propTypes = {};

About.defaultProps = {};

export default withTranslation(['global', 'home'])(About);
