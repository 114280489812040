import React from 'react';
import styled, { ThemeContext } from 'styled-components';
import PropTypes from 'prop-types';
import breakpoint from 'styled-components-breakpoint';

const StyledMap = styled.iframe`
  border:0;
  width: 100%;
  height:100%;
`;

const StyledIframeMap = styled.div`
  width: 100%;
  height: 667px;
  ${breakpoint('lg')`
    height: 448px;
  `}
`;

const IframeMap = ({ src, defaultZoom }) => {
  const { langCode } = React.useContext(ThemeContext);

  const mapLangMapping = () => {
    switch (langCode) {
      case 'tc':
        return 'zh_HK';
      case 'en':
      default:
        return 'en';
      }
  };

  const url = `${src}?width=100%&height=100%&hl=${mapLangMapping()}&q=黃竹坑道29號維他大廈+(PING PING INVESTMENT COMPANY LIMITED)&t=&z=${defaultZoom}&ie=UTF8&iwloc=B&output=embed`;
  return (
    <StyledIframeMap>
      <StyledMap src={url} frameborder="0" scrolling="no" marginheight="0" marginwidth="0" />
    </StyledIframeMap>
  )
};

IframeMap.propTypes = {
  src: PropTypes.string,
  defaultZoom: PropTypes.number,
  defaultCenter: PropTypes.object,
  googleKey: PropTypes.string,
};

IframeMap.defaultProps = {
  src: 'https://maps.google.com/maps',
  defaultZoom: 18,
};

export default IframeMap;
