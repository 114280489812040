// import {
//   midlandYellows, systemBlues, greys, greens, oranges, reds, indigos, blueGreys, lightGreens,
// } from '../colors';

const defaultTheme = {
  palette: {
    // primary: midlandYellows[500],
    // secondary: systemBlues[500],
    // midlandYellow: midlandYellows,
    // systemBlue: systemBlues,
    // grey: greys,
    // green: greens,
    // red: reds,
    // orange: oranges,
    // indigo: indigos,
    // blueGrey: blueGreys,
    // lightGreen: lightGreens,
  },
  typography: {
    size: '16px',
    lineHeight: '35px',
  },
  breakpoints: {
    xs: 0,
    sm: 0,
    md: 768,
    lg: 1280,
    xl: 1540,
  },
  row: {
    padding: 20,
  },
  col: {
    padding: 20,
  },
  container: {
    padding: 20,
    maxWidth: {
      xs: 720,
      sm: 720,
      md: 960,
      lg: 1150,
      xl: 1400,
    },
  },
  spacing: {
    unit: 8,
  },
  // shadows: {
  //   0: 'none',
  //   1: '0 0 4px 0 rgba(0,0,0,0.3)',
  // },
};

export default defaultTheme;
